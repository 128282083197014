import "../Universal/CollectionPosts/CollectionPosts.css";
import Loading from "../Universal/SingleElements/Loading/Loading";
import CollectionPost from "../Universal/CollectionPosts/CollectionPost";
import axios from "axios";
import { useState, useEffect } from "react";

const OtherArticles = () => {
  const [htmlPosts, setHtmlPosts] = useState(false);

  useEffect(() => {
    const postData = {
      content_type: "blogPost",
      limit: 3,
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((res) => {
        setHtmlPosts(res.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  if (htmlPosts) {
    return (
      <section id="collection-posts" className="mg-t-lg">
        <div className="container">
          <h2>Other articles you may like...</h2>
        </div>
        <div className="collection-posts">
          {htmlPosts.map((post) => {
            return (
              <CollectionPost
                key={post.sys.id}
                title={post.fields.blogTitle}
                slug={post.fields.slug}
                date={post.fields.blogPostDate}
                tags={post.fields.blogTags}
                image={post.fields.blogHero[0].fields.file.url}
              />
            );
          })}
        </div>
      </section>
    );
  } else {
    return (
      <section id="collection-posts" className="mg-t-lg">
        <div className="container">
          <h2>Other articles you may like...</h2>
        </div>
        <Loading />
      </section>
    );
  }
};

export default OtherArticles;
