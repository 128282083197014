import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/PrivacyPolicy";
import SinglePost from "./Pages/SinglePost";
import Layout from "./Pages/Layout";
import News from "./Pages/News";
import Projects from "./Pages/Projects";
import SingleProject from "./Pages/SingleProject";
import SingleServices from "./Pages/SingleServices";
import Contact from "./Pages/Contact";
import { useEffect, useState } from "react";
import Err404 from "./Pages/Err404";

const App = () => {
  const loc = useLocation();
  const [headerColor, setHeaderColor] = useState("transparent");
  const [splitColor, setSplitColor] = useState(false);
  const [absoluteHeader, setAbsoluteHeader] = useState(false);
  const [logoColor, setLogoColor] = useState("#0f1b30");
  const [projectSize, setProjectSize] = useState();

  const updateColorState = (color) => {
    if (color) {
      setHeaderColor(color);
    } else {
      setHeaderColor("transparent");
    }
  };

  const updateSplitColor = (result) => {
    if (result) {
      setSplitColor(result);
    } else {
      setSplitColor(result);
    }
  };

  const updateAbsoluteHeader = (result) => {
    if (result) {
      setAbsoluteHeader("absolute");
      // setHeaderColor("transparent");
    } else {
      setAbsoluteHeader("initial");
    }
  };

  const updateLogoColor = (color) => {
    if (color) {
      setLogoColor(color);
    } else {
      setLogoColor("#0f1b30");
    }
  };

  const updateProjectSize = (small) => {
    if (small === "yes") {
      setProjectSize("small");
    } else {
      setProjectSize("large");
    }
  };

  useEffect(() => {
    setAbsoluteHeader(false);
    setHeaderColor("transparent");
    setSplitColor(false);
    setLogoColor(false);
  }, [loc]);

  return (
    <Routes location={loc} key={loc.pathname}>
      <Route
        path="/"
        element={
          <Layout
            background={headerColor}
            split={splitColor}
            absolute={absoluteHeader}
            color={logoColor}
            location={loc}
            size={projectSize}
          />
        }
      >
        <Route
          path="*"
          element={
            <Err404
              updateLogoColor={updateLogoColor}
              updateAbsoluteHeader={updateAbsoluteHeader}
            ></Err404>
          }
        ></Route>
        <Route index element={<HomePage />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="service/:slug" element={<SingleServices />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="news" element={<News />} />
        <Route path="post/:slug" element={<SinglePost />} />
        <Route path="projects" element={<Projects />} />
        <Route
          path="project/:slug"
          element={
            <SingleProject
              headerColor={updateColorState}
              splitHeaderColor={updateSplitColor}
              absoluteHeader={updateAbsoluteHeader}
              projectSize={updateProjectSize}
            />
          }
        />
        <Route path="contact" element={<Contact />} />
        {/* REDIRECTS */}
        {/* Standard */}
        <Route
          path="/about-us-agency"
          element={<Navigate to="/about" replace />}
        />
        <Route
          path="/contact-us"
          element={<Navigate to="/contact" replace />}
        />
        <Route
          path="/arrange-a-callback"
          element={<Navigate to="/contact" replace />}
        />
        <Route path="/our-clients" element={<Navigate to="/about" replace />} />
        <Route path="/blog" element={<Navigate to="/news" replace />} />;
        <Route
          path="/contact-us"
          element={<Navigate to="/contact" replace />}
        />
        <Route
          path="/terms-conditions"
          element={<Navigate to="/terms" replace />}
        />
        {/* Projects */}
        <Route
          path="/portfolio/branding"
          element={<Navigate to="/project/branding" replace />}
        />
        <Route
          path="/portfolio/adidas-apparel"
          element={<Navigate to="/project/adidas" replace />}
        />
        <Route
          path="/portfolio/gorilla-juice"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/247-property-management"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/cyrus"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/imracoustics"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/ocean-property"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/3407"
          element={<Navigate to="/project/drivenmedia" replace />}
        />
        <Route
          path="/portfolio/brightstone"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/giraffe-interior-design"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/pizzavend"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/odiliaclark"
          element={<Navigate to="/project/odiliaclark" replace />}
        />
        <Route
          path="/portfolio/life-u-design"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/our-work"
          element={<Navigate to="/projects" replace />}
        />
        <Route
          path="/portfolio/project-wingman"
          element={<Navigate to="/project/project-wingman" replace />}
        />
        {/* News */}
        <Route
          path="/where-did-it-all-begin"
          element={<Navigate to="/post/where-did-it-all-begin" replace />}
        />
        <Route
          path="/search-engine-optimization"
          element={<Navigate to="/post/search-engine-optimization" replace />}
        />
        <Route
          path="/questions-for-stephen"
          element={<Navigate to="/post/questions-for-stephen" replace />}
        />
        <Route
          path="/ran-park-explores-the-chaos-of-konglish-in-a-new-zine"
          element={<Navigate to="/post/the-history-of-our-home" replace />}
        />
        <Route
          path="/questions-for-harry"
          element={<Navigate to="/post/questions-for-harry" replace />}
        />
        ;
        <Route
          path="/famous-brands-reimagined"
          element={<Navigate to="/post/famous-brands-reimagined" replace />}
        />
        <Route
          path="/why-is-a-strong-brand-important"
          element={
            <Navigate to="/post/why-is-a-strong-brand-important" replace />
          }
        />
        <Route
          path="/our-favourite-sans-serif-fonts-of-2019"
          element={
            <Navigate
              to="/post/our-favourite-sans-serif-fonts-of-2019"
              replace
            />
          }
        />
        <Route
          path="/internet-explorers-end-of-life"
          element={
            <Navigate to="/post/internet-explorers-end-of-life" replace />
          }
        />
        <Route
          path="/handy-tools-for-web-developers-boosting-efficiency-and-productivity"
          element={
            <Navigate
              to="/post/handy-tools-for-web-developers-boosting-efficiency-and-productivity"
              replace
            />
          }
        />
        {/* Services */}
        <Route
          path="/services/seo"
          element={<Navigate to="/services" replace />}
        />
        <Route
          path="/design"
          element={<Navigate to="/service/design" replace />}
        />
        <Route
          path="/services/design"
          element={<Navigate to="/service/design" replace />}
        />
        <Route
          path="/services/branding"
          element={<Navigate to="/service/branding" replace />}
        />
        <Route
          path="/brand-strategy"
          element={<Navigate to="/service/branding" replace />}
        />
        <Route
          path="/services/brand-strategy"
          element={<Navigate to="/service/branding" replace />}
        />
        <Route
          path="/services/brand-strategy"
          element={<Navigate to="/service/branding" replace />}
        />
        <Route
          path="/web-design"
          element={<Navigate to="/service/web-design" replace />}
        />
        <Route
          path="/services/web-design"
          element={<Navigate to="/service/web-design" replace />}
        />
        <Route
          path="/marketing"
          element={<Navigate to="/services" replace />}
        />
        <Route
          path="/services/marketing"
          element={<Navigate to="/services" replace />}
        />
        <Route
          path="/social-media-advertising"
          element={<Navigate to="/service/social-media-advertising" replace />}
        />
        <Route
          path="/services/social-media-advertising"
          element={<Navigate to="/service/social-media-advertising" replace />}
        />
        <Route
          path="/shopify-development"
          element={<Navigate to="/service/ecommerce" replace />}
        />
        <Route
          path="/services/shopify-development"
          element={<Navigate to="/service/ecommerce" replace />}
        />
      </Route>
    </Routes>
  );
};

export default App;
