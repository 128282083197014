import "./HomeCarousel.css";
import { Carousel } from "3d-react-carousal";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";
import { useEffect, useState } from "react";
import Work from "./HomePageImages/our-work.svg";
import { Link } from "react-router-dom";

function HomeCarousel(props) {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const imgArr = props.images.map((img, index) => (
      <img
        loading="lazy"
        height="700px"
        width="700px"
        key={img.sys.id}
        src={img.fields.file.url}
        alt={img.fields.title}
      />
    ));
    setSlides(imgArr);
  }, [props.images]);

  if (slides) {
    return (
      <div className="container carousel-con">
        <Carousel slides={slides} autoplay={false} interval={1000} />
        <div className="work-btn">
          <Link onClick={ScrollTop} to="/projects">
            <button className="blue-btn">View our work</button>
          </Link>
        </div>
        <div className="our-work">
          <img
            loading="lazy"
            width="262px"
            height="96px"
            src={Work}
            alt="Work"
          />
        </div>
      </div>
    );
  }
}

export default HomeCarousel;
