import SEO from "../SEO";

function Terms() {
  return (
    <div className="container">
      <SEO
        title="Hyphen Creative | Terms & Conditions"
        description="Hyphen Creative are British-based design agency that excels in the design, and deployment of innovative cross-platform brand experiences."
        keywords="Hyphen Creative, Midlands Design Agency, Belper, Derby, Nottingham, Design, Websites, Web design, Development, Branding, Photography, Advertising, SEO Company, Print, Marketing Company, Graphic"
      />
      <h1>Terms & Conditions</h1>

      <h2>Acknowledgment</h2>
      <p>
        It is not necessary for any client to have signed an acceptance of these
        terms and conditions for them to apply. If a client accepts a quote from
        Hyphen Creative, then the client will be deemed to have satisfied
        themselves as to the terms applying and have accepted these terms and
        conditions in full.
      </p>
      <p>
        Please read these terms and conditions carefully as any purchase or use
        of our services implies that you have read and accepted our terms and
        conditions.
      </p>

      <h2>Charges</h2>
      <p>
        Charges for services to be provided by Hyphen Creative are defined in
        the project quotation that the client will receive via e-mail. The
        quotations we send are valid for a period of 10 days. Hyphen Creative
        reserves the right to alter or decline to provide a quotation after
        expiry of the 10 days.
      </p>
      <p>
        Unless agreed otherwise with the Client, all services that Hyphen
        Creative provide will require an advance payment of fifty (50) percent
        of the project quotation total before the work is supplied to the Client
        for review. A second charge of fifty (50) percent is required after the
        development stage, with the remaining fifty (50) percent of the project
        quotation total due upon completion of the work, prior to upload to the
        server or release of materials.
      </p>

      <h2>Client Review</h2>
      <p>
        Hyphen Creative will allow the client an opportunity to review the
        services Hyphen Creative provide during the design phase and once the
        overall development is completed. At the completion of the project, the
        client will sign off the work completed by Hyphen Creative. Any further
        work requested by the client will encounter extra charges.
      </p>

      <h2>Completion Time and Content Control</h2>
      <p>
        Hyphen Creative will install and publicly post or supply the Client’s
        products or services by the date specified in the project proposal, or
        the date agreed with Client upon Hyphen Creative receiving initial
        payment. Unless a delay is specifically requested by the Client or by
        Hyphen Creative.
      </p>
      <p>
        In return, the Client agrees to delegate a single individual as a
        primary contact to aid Hyphen Creative. During the project, Hyphen
        Creative will require the Client to provide website content; text,
        images, movies and sound files if needed.
      </p>

      <h2>Client Failing to Provide Information</h2>
      <p>
        Hyphen Creative is a small business, therefore, keep our clients
        satisfied and to remain efficient, we need to ensure that the work we
        have scheduled is carried out at the programmed time.
      </p>
      <p>
        On any occasion where progress cannot be made with the services Hyphen
        Creative provide because we have not been given the information needed
        in the agreed time frame, and in resulting in this we are delayed as
        result, we reserve the right to impose a surcharge of up to 25%
      </p>
      <p>
        If you agree to provide us with the required information and
        subsequently fail to do within one week of project commencement, we
        reserve the right to close the project and the balance remaining becomes
        payable immediately.
      </p>
      <p>
        Simply put, all the above condition says is do not give us the go-ahead
        to start until you are ready to do so.
      </p>

      <h2>Payment</h2>
      <p>
        Invoices will be provided by Hyphen Creative. Invoices are normally sent
        via email; however, the Client may choose to receive hard copy invoices.
        Invoices are due upon receipt.
      </p>
      <p>
        Accounts that remain unpaid thirty (30) days after the date of the
        invoice will be assessed a service charge in the amount of the higher of
        ten percent (10%) per month of the total amount due.
      </p>
      <p>
        If payment of any outstanding invoices is not received within the
        specified due date, we reserve the right to temporarily disable or
        permanently remove client collateral including but not limited to
        websites, online content physical products without further notice. This
        includes all associated files, data, and content. Restoration of the
        website or any related services will only be possible upon full
        settlement of the outstanding balance. Please ensure timely payment to
        avoid any disruption to your services.
      </p>

      <h2>Web Browsers (web development)</h2>
      <p>
        Hyphen Creative makes every effort to ensure websites are designed to be
        viewed by the majority of visitors. Our websites are designed to work
        with most of the popular current browsers (e.g. Firefox, Safari, Edge,
        Google Chrome, etc.). Client agrees that Hyphen Creative cannot
        guarantee perfect functionality with all browser software across
        different operating systems.
      </p>
      <p>
        Hyphen Creative cannot accept responsibility for web pages which do not
        display acceptably in new versions of browsers released after the
        website have been designed and handed over to the Client. As such,
        Hyphen Creative reserves the right to quote for any work involved in
        changing the website design or website code for it to work with updated
        browser software.
      </p>

      <h2>Cancelation of Contract</h2>
      <p>
        If you wish to cancel your contract with Hyphen Creative you must give a
        month’s notice.
      </p>

      <h2>Design Credit (web development)</h2>
      <p>
        A link to Hyphen creative will appear in the footer of all website
        Hyphen Creative design and build. If the client requests to get this
        link removed, a fee of 10% of the total design and development costs
        will be applied. The client also agrees that once the website is
        completed then Hyphen Creative may use it in their portfolio.
      </p>

      <h2>Data Protection</h2>
      <p>
        Hyphen Creative agrees to keep the important information of its clients
        confidential, including all administration areas of websites and details
        of referrers and those who are referred. All such data recorded by a
        website will be completely secure, and all information will be treated
        as confidential in accordance with the Data Protection Act.
      </p>

      <h2>Copyright</h2>
      <p>
        Unless negotiated and agreed in writing, the copyrights of general
        artwork, commissioned artwork, illustrations, website design, and
        development belong to Hyphen Creative.
      </p>

      <h2>Liability</h2>
      <p>
        Hyphen Creative Limited shall not be liable for any direct or indirect
        loss or damage suffered by the client or any third party resulting from
        the use or inability to use the website or any of its content, including
        but not limited to loss of earnings, profits, contracts, data, goodwill,
        or any other losses arising from or in connection with the use or
        inability to use the website.
      </p>
      <p>
        Hyphen Creative Limited shall not be liable for any errors, omissions,
        or inaccuracies in the content of the website, or for any actions taken
        in reliance on such content.
      </p>
      <p>
        Hyphen Creative Limited shall not be liable to the Client or any third
        party for any damages or loss of income arising from or related to the
        use of the Hyphen Creative Limited products or services, including but
        not limited to any direct, indirect, incidental, consequential, special,
        or punitive damages, or any damages whatsoever, including but not
        limited to damages for loss of use, data, profits, or other intangible
        losses, whether in contract, tort, strict liability, or otherwise, even
        if the Company has been advised of the possibility of such damages.
      </p>
      <p>
        Hyphen Creative Limited makes no representations or warranties, express
        or implied, regarding the products or services, and disclaims all
        warranties, including but not limited to any implied warranties of
        merchantability or fitness for a particular purpose.
      </p>
      <p>
        Unless otherwise agreed Hyphen Creative reserve the right to include
        assets, logos, and other branded collateral throughout the website,
        portfolio and across social media.
      </p>
    </div>
  );
}

export default Terms;
