import "./CollectionPosts.css";
import axios from "axios";
import CollectionPost from "./CollectionPost";
import { useState, useEffect } from "react";
import Loading from "../SingleElements/Loading/Loading";

const CollectionPosts = () => {
  const [htmlPosts, setHtmlPosts] = useState(false);

  useEffect(() => {
    const postData = {
      content_type: "blogPost",
      limit: 3,
      order: "-fields.blogPostDate",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((res) => {
        setHtmlPosts(res.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  if (!htmlPosts) {
    return (
      <section id="collection-posts" className="mg-t-lg">
        <div className="container">
          <h2>
            A collection of stuff
            <br />
            <span>from news to office shenanigans...</span>
          </h2>
        </div>
        <Loading />;
      </section>
    );
  } else {
    return (
      <section id="collection-posts" className="mg-t-lg">
        <div className="container">
          <h2>
            A collection of stuff
            <br />
            <span>from news to office shenanigans...</span>
          </h2>
        </div>
        <div className="collection-posts">
          {htmlPosts.map((post) => {
            return (
              <CollectionPost
                key={post.sys.id}
                title={post.fields.blogTitle}
                slug={post.fields.slug}
                date={post.fields.blogPostDate}
                tags={post.fields.blogTags}
                image={post.fields.blogHero[0].fields.file.url}
              />
            );
          })}
        </div>
      </section>
    );
  }
};

export default CollectionPosts;
