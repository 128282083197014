const inView = (el) => {
  const handler = function () {
    if (el.getBoundingClientRect().top <= 0 + window.innerHeight) {
      el.classList.add("animating");
      setTimeout(() => {
        el.classList.remove("scroll-into-view");
        el.classList.remove("animating");
      }, 1000);
      window.removeEventListener("scroll", handler);
    }
  };
  return handler;
};

export default function ScrollIntoView(elements, offset) {
  elements.forEach((el, index) => {
    el.classList.remove("scroll-into-view");
    if (index >= offset) {
      el.classList.add("scroll-into-view");
      window.addEventListener("scroll", inView(el));
    }
  });
}
