import SEO from "../SEO";
import "../Components/ServicesPage/ServicesPage.css";
import GraphicDesign from "../Components/ServicesPage/ServicesPageImages/service-graphic-design.webp";
import Branding from "../Components/ServicesPage/ServicesPageImages/branding-services.webp";
import Photography from "../Components/ServicesPage/ServicesPageImages/CBD_Fitness_Patchs_NEW.webp";
import WebDesign from "../Components/ServicesPage/ServicesPageImages/Service-Web-Design.webp";
import Packaging from "../Components/ServicesPage/ServicesPageImages/packagin-services.webp";
import UXDesign from "../Components/ServicesPage/ServicesPageImages/ux-services.webp";
import PrintDesign from "../Components/ServicesPage/ServicesPageImages/Service-Print-Design.webp";
import SocialMedia from "../Components/ServicesPage/ServicesPageImages/Service-Social-Media.webp";
import ImageEditing from "../Components/ServicesPage/ServicesPageImages/New-Editing.webp";
import Ecommerce from "../Components/ServicesPage/ServicesPageImages/ecommerce-services.webp";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";
import { Link } from "react-router-dom";
import Circle from "../Components/Universal/Svgs/Circle";
import Parallax from "react-rellax";
import Open from "../Components/Footer/FooterImages/open-accordion.svg";
import Close from "../Components/Footer/FooterImages/close-accordion.svg";
import LoopDown from "../Components/ServicesPage/ServicesPageImages/loop-down.svg";
import ScrollIntoView from "../Components/Universal/SingleFunctions/ScrollIntroView";
import { useEffect, useCallback } from "react";

const toggleAccordion = (item) => {
  if (item.classList.contains("open")) {
    item.querySelector(".accordion-container").style.height = `0px`;
    item.classList.remove("open");
  } else {
    item.querySelector(".accordion-container").style.height = `${
      item.querySelector(".accordion-text").getBoundingClientRect().height
    }px`;
    item.classList.add("open");
  }
};

function Services() {
  // Check for touchscreen
  const isTouchDevice = useCallback(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  useEffect(() => {
    ScrollIntoView(
      document.querySelectorAll("#services-split-text .each-accordion"),
      0
    );
    if (isTouchDevice) {
      document.querySelector("#all-services").classList.add("touch");
    }
  }, [isTouchDevice]);

  return (
    <div>
      <SEO
        title="Hyphen Creative | Services | Print • Web Design • Branding • Advertising"
        description="Hyphen Creative offer a range of services including web design, branding, print design, marketing, seo and graphic design. Servicing Derby and the UK."
        keywords="web design derby"
      />
      <section className="container center" id="hero-services">
        <h3>We offer services including</h3>
        <h1>
          <Link to="/service/design/" onClick={ScrollTop}>
            Graphic Design
          </Link>
          ,{" "}
          <Link to="/service/branding/" onClick={ScrollTop}>
            Branding
          </Link>
          , <br />{" "}
          <Link to="/service/web-design/" onClick={ScrollTop}>
            Web Design
          </Link>
          ,{" "}
          <Link to="/service/web-development/" onClick={ScrollTop}>
            Web Development
          </Link>
          , <br />{" "}
          <Link to="/service/photography/" onClick={ScrollTop}>
            Photography
          </Link>
        </h1>
        <p className="jeff">
          And{" "}
          <span>
            <Circle stroke="5" />
            so much
          </span>{" "}
          more!
        </p>
      </section>

      <section id="all-services">
        <div className="row-1">
          <Link
            id="graphic-design"
            to="/service/design"
            onClick={() => ScrollTop()}
            text="Graphic Design"
          >
            <img
              fetchpriority="high"
              width="769px"
              height="736px"
              src={GraphicDesign}
              alt="Design Preview"
            />
          </Link>
          <Parallax speed={-2}>
            <Link
              to="/service/branding"
              id="branding"
              onClick={() => ScrollTop()}
              text="Branding"
            >
              <img
                width="769px"
                height="450px"
                src={Branding}
                alt="Branding Preview"
              />
            </Link>
          </Parallax>
        </div>
        <div className="row-2">
          <Parallax speed={-0.5}>
            <Link
              to="/service/photography"
              onClick={() => ScrollTop()}
              id="photography"
              text="Photography"
            >
              <img
                width="639px"
                height="514px"
                src={Photography}
                alt="Photography Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
        </div>
        <div className="row-3">
          <Parallax speed={1}>
            <Link
              to="/service/web-design"
              onClick={() => ScrollTop()}
              id="web-design"
              text="Web Design"
            >
              <img
                width="616px"
                height="589px"
                src={WebDesign}
                alt="Web Design Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
          <Parallax speed={-1}>
            <Link
              to="/service/packaging-design"
              id="packaging"
              onClick={() => ScrollTop()}
              text="Packaging"
            >
              <img
                width="508px"
                height="361px"
                src={Packaging}
                alt="Packaging Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
        </div>
        <div className="row-4">
          <Parallax speed={0}>
            <Link
              to="/service/ux-design"
              onClick={() => ScrollTop()}
              id="ux-design"
              text="UX Design"
            >
              <img
                width="835px"
                height="657px"
                src={UXDesign}
                alt="UX Design Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
        </div>
        <div className="row-5">
          <Parallax speed={-0.5}>
            <Link
              to="/service/print-design"
              onClick={() => ScrollTop()}
              id="print-design"
              text="Print Design"
            >
              <img
                width="769px"
                height="736px"
                src={PrintDesign}
                alt="Print Design Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
          <Parallax speed={1}>
            <Link
              to="/service/social-media-advertising"
              onClick={() => ScrollTop()}
              id="social-media"
              text="Social Media"
            >
              <img
                width="769px"
                height="450px"
                src={SocialMedia}
                alt="Social Media Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
        </div>
        <div className="row-6">
          <Parallax speed={0.5}>
            <Link
              to="/service/image-editing"
              onClick={() => ScrollTop()}
              id="image-editing"
              text="Image Editing"
            >
              <img
                width="770px"
                height="555px"
                src={ImageEditing}
                alt="Images Editing Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
        </div>
        <div className="row-7">
          <Parallax speed={0.5}>
            <Link
              to="/service/ecommerce"
              onClick={() => ScrollTop()}
              id="ecommerce"
              text="eCommerce"
            >
              <img
                width="1363px"
                height="550px"
                src={Ecommerce}
                alt="eCommerce Preview"
                loading="lazy"
              />
            </Link>
          </Parallax>
        </div>
      </section>
      <section id="services-split-text">
        <div className="container">
          <div className="two-two">
            <div className="other-serv">
              <img
                width="136.017px"
                height="109.313px"
                src={LoopDown}
                alt="Loop Down"
                loading="lazy"
              />
              <p className="jeff">Other services</p>
            </div>
            <div className="two-one">
              <h2>
                <span className="bold">A small agency</span> <br />
                with big ideas.
              </h2>
              <p>
                Our team has so much to offer - we offer all our services to
                every client and pride ourselves on our cost-effective design
                and marketing services. Each project we complete is crafted
                in-house at our offices in Belper, Derby. So whether you're
                looking for a new brand identity or you need a bespoke eCommerce
                website, you've come to the right place.
              </p>
            </div>
            <div className="two-one">
              <div className="cta-drops full">
                <div className="accordion">
                  <div className="each-accordion">
                    <button
                      className="accordion-toggle p-lg"
                      onClick={(e) => {
                        toggleAccordion(e.target.closest(".each-accordion"));
                      }}
                    >
                      Digital Marketing
                      <span className="open">
                        <img
                          width="14px"
                          height="14px"
                          src={Open}
                          alt="Plus"
                          loading="lazy"
                        />
                      </span>
                      <span className="close">
                        <img
                          width="14px"
                          height="2px"
                          src={Close}
                          alt="Minus"
                          loading="lazy"
                        />
                      </span>
                    </button>
                    <div className="accordion-container">
                      <div className="accordion-text">
                        <p>
                          Creation and rejuvenation of a brand is what we
                          specialise in. Our marketing plans are designed around
                          you and your brand and are created with your customers
                          in mind. We innovate and accelerate through the gears
                          to achieve the best we can for all of our clients.
                        </p>
                        <Link to="/contact/" onClick={ScrollTop}>
                          <p className="underline-link">Find Out More</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="each-accordion">
                    <button
                      className="accordion-toggle p-lg"
                      onClick={(e) => {
                        toggleAccordion(e.target.closest(".each-accordion"));
                      }}
                    >
                      Brand Strategy
                      <span className="open">
                        <img
                          width="14px"
                          height="14px"
                          src={Open}
                          alt="Plus"
                          loading="lazy"
                        />
                      </span>
                      <span className="close">
                        <img
                          width="14px"
                          height="2px"
                          src={Close}
                          alt="Minus"
                          loading="lazy"
                        />
                      </span>
                    </button>
                    <div className="accordion-container">
                      <div className="accordion-text">
                        <p>
                          We help to bring new brands to life, bring back those
                          brands that have lost their way and breathe life into
                          old brands. From logo creation through to brand tone
                          of voice we will cover everything to create a brand
                          your customers simply can't ignore.
                        </p>
                        <Link to="/contact/" onClick={ScrollTop}>
                          <p className="underline-link">Find Out More</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="each-accordion">
                    <button
                      className="accordion-toggle p-lg"
                      onClick={(e) => {
                        toggleAccordion(e.target.closest(".each-accordion"));
                      }}
                    >
                      Website Maintenance
                      <span className="open">
                        <img
                          width="14px"
                          height="14px"
                          src={Open}
                          alt="Plus"
                          loading="lazy"
                        />
                      </span>
                      <span className="close">
                        <img
                          width="14px"
                          height="2px"
                          src={Close}
                          alt="Minus"
                          loading="lazy"
                        />
                      </span>
                    </button>
                    <div className="accordion-container">
                      <div className="accordion-text">
                        <p>
                          Our Website Maintenance keep your website current with
                          the latest software and security updates. Enjoy
                          ongoing support, including blog, product, testimonial
                          updates, and more, ensuring your site stays at its
                          best.
                        </p>
                        <Link to="/contact/" onClick={ScrollTop}>
                          <p className="underline-link">Get In Touch</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="each-accordion">
                    <button
                      className="accordion-toggle p-lg"
                      onClick={(e) => {
                        toggleAccordion(e.target.closest(".each-accordion"));
                      }}
                    >
                      Business IT Support
                      <span className="open">
                        <img
                          width="14px"
                          height="14px"
                          src={Open}
                          alt="Plus"
                          loading="lazy"
                        />
                      </span>
                      <span className="close">
                        <img
                          width="14px"
                          height="2px"
                          src={Close}
                          loading="lazy"
                          alt="Minus"
                        />
                      </span>
                    </button>
                    <div className="accordion-container">
                      <div className="accordion-text">
                        <p>
                          In addition to our primary services, we now provide
                          comprehensive Business IT support encompassing
                          hardware and software assistance. This includes email
                          setup, on-site or remote coaching, and technical
                          support.
                        </p>
                        <Link to="/contact/" onClick={ScrollTop}>
                          <p className="underline-link">Get In Touch</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="each-accordion">
                    <button
                      className="accordion-toggle p-lg"
                      onClick={(e) => {
                        toggleAccordion(e.target.closest(".each-accordion"));
                      }}
                    >
                      Website Hosting
                      <span className="open">
                        <img
                          width="14px"
                          height="14px"
                          src={Open}
                          alt="Plus"
                          loading="lazy"
                        />
                      </span>
                      <span className="close">
                        <img
                          width="14px"
                          height="2px"
                          src={Close}
                          alt="Minus"
                          loading="lazy"
                        />
                      </span>
                    </button>
                    <div className="accordion-container">
                      <div className="accordion-text">
                        <p>
                          Benefit from our dedicated server, strategically
                          located in Nottingham, UK for peak performance. Enjoy
                          Gold support and round-the-clock monitoring,
                          guaranteeing minimal downtime. Contact us today if
                          you're considering migrating your website hosting.
                        </p>
                        <Link to="/contact/" onClick={ScrollTop}>
                          <p className="underline-link">Get In Touch</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
