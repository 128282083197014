import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, useLocation } from "react-router-dom";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";
import "./SingleProjectPage.css";
import NavPrev from "../ProjectsPage/ProjectPageImages/nav-prev.svg";
import NavNext from "../ProjectsPage/ProjectPageImages/nav-next.svg";

const SingleProjectLarge = (props) => {
  const location = useLocation();
  let array;
  let index;

  if (location.state) {
    ({ array, index } = location.state);
  }
  return (
    <div>
      <section
        id="single-project-hero"
        style={{
          background: props.project.fields.BackgroundColour,
        }}
      >
        <div className="container" id="hero-content">
          <div
            className="single-post-intro"
            id="link-intro"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(props.project.fields.heroContent),
            }}
          />
        </div>
        <div className="main-featured">
          <img
            src={props.project.fields.heroBackground.fields.file.url}
            alt={props.project.fields.heroBackground.fields.title}
          />
        </div>
      </section>

      <section id="pro-intro" className="main-intro">
        <div className="container two-two">
          <div className="two-one">
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(props.project.fields.introContent),
              }}
            />
            <img
              className="pro-logo"
              src={props.project.fields.logo.fields.file.url}
              alt={props.project.fields.logo.fields.title}
            />
          </div>
          <div className="two-one">
            <div className="client-info">
              <p className="sm-text">Client</p>
              <p>{props.project.fields.clientName}</p>
            </div>
            <div className="client-info">
              <p className="sm-text">Website</p>
              <p>
                <Link
                  to={"https://" + props.project.fields.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.project.fields.website}
                </Link>
              </p>
            </div>

            <div className="client-info">
              <p className="sm-text">Date</p>
              <p>{props.project.fields.date}</p>
            </div>

            <div className="client-info">
              <p className="sm-text">Services</p>
              <p>{props.project.fields.services}</p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="two-img"
        style={{
          background: props.project.fields.BackgroundColour,
        }}
      >
        {props.project.fields.images.map((img) => (
          <img
            key={img.sys.id}
            src={img.fields.file.url}
            alt={img.fields.title}
          />
        ))}
      </section>

      <section
        id="fw-img"
        style={{
          background: props.project.fields.BackgroundColour,
        }}
      >
        {props.project.fields.fullWidthImages.map((img) => (
          <img
            key={img.sys.id}
            src={img.fields.file.url}
            alt={img.fields.title}
          />
        ))}
      </section>

      <section id="pro-intro">
        <div className="container brief-outcome">
          <div className="two-one">
            <h2>The brief...</h2>
          </div>
          <div className="two-one">
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(props.project.fields.theBrief),
              }}
            />
          </div>
        </div>
      </section>

      <section
        id="fw-img"
        style={{
          background: props.project.fields.BackgroundColour,
        }}
      >
        {props.project.fields.fullWidthImages2.map((img) => (
          <img
            key={img.sys.id}
            src={img.fields.file.url}
            alt={img.fields.title}
          />
        ))}
      </section>
      <section
        id="two-img"
        style={{
          background: props.project.fields.BackgroundColour,
        }}
      >
        {props.project.fields.images3.map((img) => (
          <img
            key={img.sys.id}
            src={img.fields.file.url}
            alt={img.fields.title}
          />
        ))}
      </section>

      <section id="pro-intro">
        <div className="container over-half-text">
          <div className="two-one">
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(
                  props.project.fields.contentSection
                ),
              }}
            />
          </div>
        </div>
      </section>

      <section id="one-img" className="slider-oppo-arrows">
        <Carousel
          showArrows={true}
          showStatus={false}
          showIndicators={false}
          renderThumbs={() => {
            return;
          }}
        >
          {props.project.fields.gallery.map((img) => (
            <img
              key={img.sys.id}
              src={img.fields.file.url}
              alt={img.fields.title}
            />
          ))}
        </Carousel>
      </section>

      <section id="pro-intro" className="quote">
        <div className="container">
          <div className="two-one">
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(props.project.fields.quote),
              }}
            />
          </div>
        </div>
      </section>

      <section
        id="two-img"
        style={{
          background: props.project.fields.BackgroundColour,
        }}
      >
        <img
          src={props.project.fields.images4[0].fields.file.url}
          alt={props.project.fields.images4[0].fields.title}
        />
        <img
          src={props.project.fields.images4[1].fields.file.url}
          alt={props.project.fields.images4[1].fields.title}
        />
      </section>

      <section id="pro-intro">
        <div className="container brief-outcome">
          <div className="two-one">
            <h2>The Outcome...</h2>
          </div>
          <div className="two-one">
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(props.project.fields.theOutcome),
              }}
            />
          </div>
        </div>
      </section>

      {location.state ? (
        <section id="single-post-nav">
          <div className="container">
            <div className="single-post-nav">
              {index !== 0 ? (
                <div className="prev">
                  <Link
                    to={"/project/" + array[index - 1].fields.slug}
                    onClick={ScrollTop}
                    state={{
                      array: array,
                      index: index - 1,
                      reload: true,
                    }}
                  >
                    <p>Previous</p>
                    <img src={NavPrev} alt="Prev Arrow" />
                    <span className="p-lg">
                      {array[index - 1].fields.projectTitle}
                    </span>
                  </Link>
                </div>
              ) : (
                ""
              )}
              {array.length - 1 !== index ? (
                <div className="next">
                  <Link
                    to={"/project/" + array[index + 1].fields.slug}
                    onClick={ScrollTop}
                    state={{
                      array: array,
                      index: index + 1,
                      reload: true,
                    }}
                  >
                    <p>Next</p>
                    <span className="p-lg">
                      {array[index + 1].fields.projectTitle}
                    </span>
                    <img src={NavNext} alt="Next Arrow" />
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};

export default SingleProjectLarge;
