import { useState } from "react";
import "./Header.css";
import HeaderMenuToggle from "./HeaderMenuToggle";
import HeaderMenu from "./HeaderMenu";
import { Link, useLocation } from "react-router-dom";
// import { motion } from "framer-motion";

function Header(props) {
  let loc = useLocation();
  const [menuState, setMenuState] = useState("closed");
  const watchScroll = () => {
    setMenuState("closed");
    document.removeEventListener("scroll", watchScroll);
    return;
  };

  const updateState = () => {
    if (menuState === "closed") {
      setMenuState("open");
      document.addEventListener("scroll", watchScroll);
      return;
    } else {
      setMenuState("closed");
      return;
    }
  };

  return (
    <header
      style={{
        background: props.split
          ? `linear-gradient(90deg, ${props.background} 50%, rgba(255,255,255,0) 50%)`
          : props.background,
      }}
      className={`${props.absolute}${
        loc.pathname.indexOf("project") >= 1 ? " project" : ""
      }${props.split ? " split" : ""}${props.size === "small" ? " small" : ""}${
        props.size === "large" ? " large" : ""
      }`}
    >
      <div className="top">
        <p style={{ color: props.color }}>
          Say <span className="filson-b">no</span> to mediocre
        </p>
        {/* <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        > */}
        <span>
          <Link to="/" aria-label="Home">
            {props.color === "white" ? (
              <span>
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 217.56 40.17"
                  width="217.56"
                  height="40.17"
                  className="logo animate white"
                >
                  <g>
                    <g>
                      <path
                        fill="#fff"
                        className="cls-2 letter-p"
                        d="M75.22,39.17V6h5.92s21.01,0,21.01,0v2.7c0,6.68-2.11,11.91-6.29,15.55-4.53,3.95-10.53,5.06-14.76,5.3l-.47.03v9.6h-5.42ZM80.64,24.74l.53-.04c3.26-.22,7.84-1.08,11.13-3.96,2.48-2.24,4.37-5.75,4.7-8.76l.06-.55h-16.42v13.31Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        className="cls-2 letter-h"
                        d="M132.15,39.17v-1.27c0-5.08-1.48-8.96-4.41-11.52-3.63-3.17-8.77-3.89-12.44-3.93h-.51s0,16.71,0,16.71h-5.46V6h5.46v10.98h.5c4.6.05,11.11,1,16.01,5.26l.83.72.02-1.08V6h5.46v33.17h-5.46Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        className="cls-2 letter-h2"
                        d="M23.31,39.17v-16.72h-.51c-3.67.05-8.81.76-12.44,3.93-2.89,2.53-4.37,6.35-4.41,11.35v1.44H.5V6h5.46v16.86l.79-.58c4.95-4.3,11.46-5.25,16.06-5.29h.5V6h5.46v33.17h-5.46Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        className="cls-2 letter-e"
                        d="M144.78,39.17V6h24.62v5.29h-19.15v12.38l.78-.54c4.71-3.26,11.19-4.52,15.82-4.63l.04,5.47c-2.95.14-5.87.68-8.68,1.61-4.83,1.69-7.56,4.47-7.92,8.05l-.05.55h19.16v5h-24.62Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        className="cls-2 letter-y"
                        d="M47.02,39.17v-8.73c0-3.56.55-6.89,1.63-9.9l.08-.22-.11-.2c-3.36-5.9-7.16-8.5-12.66-8.65v-5.47c6.34.13,11.37,2.81,15.34,8.18l.39.53.4-.53c.46-.6.94-1.16,1.43-1.68,3.75-4.03,9.03-6.39,14.53-6.51v5.47c-10.22.3-15.56,9.71-15.56,18.96v8.73h-5.46Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#fff"
                        className="cls-2 letter-n"
                        d="M176.58,39.17V6h5.52c.68,5.4,2.21,10.63,4.53,15.55,2.31,4.93,6.31,10.98,12.24,11.73l.56.07V6h5.46v33.16h-5.96c-7.79-.57-13-7.25-15.99-12.75l-.94-1.72v14.47h-5.42Z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      fill="#fff"
                      className="cls-1 letter-t"
                      d="M211.06,3.57h-.6V.52h-1.31v-.52h3.22v.52h-1.31v3.04Z"
                    />
                    <path
                      fill="#fff"
                      className="cls-1 letter-m"
                      d="M215.29,3.57l-.98-1.89c-.11-.32-.21-.6-.28-.87.02.31.03.65.03.92v1.84h-.57V0h.74l1.04,2.06c.13.37.15.41.25.74.13-.38.18-.52.25-.73l1.04-2.05h.74v3.55h-.57v-1.84c0-.29.01-.59.03-.92-.1.34-.18.58-.31.92l-.95,1.84h-.47Z"
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_2"
                  data-name="Layer 2"
                  viewBox="0 0 35.51 41.43"
                  className="logo-mob animate white"
                >
                  <g id="Layer_1-2" data-name="Layer 1">
                    <g xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <rect
                          className="cls-1 letter-e"
                          x="28.17"
                          y=".5"
                          width="6.84"
                          height="40.43"
                          fill="#fff"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fff"
                          className="cls-1 letter-p"
                          d="M.5,40.93V.5h6.84v20.45l.83-.71c3.66-3.02,8.03-5,12.68-5.78v6.98c-3,.64-5.82,2-8.19,3.95-3.47,3.04-5.26,7.6-5.32,13.58v1.96H.5Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            ) : (
              <span>
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 217.56 40.17"
                  width="217.56"
                  height="40.17"
                  className="logo animate"
                >
                  <g>
                    <g>
                      <path
                        fill="#121c30"
                        className="cls-2 letter-p"
                        d="M75.22,39.17V6h5.92s21.01,0,21.01,0v2.7c0,6.68-2.11,11.91-6.29,15.55-4.53,3.95-10.53,5.06-14.76,5.3l-.47.03v9.6h-5.42ZM80.64,24.74l.53-.04c3.26-.22,7.84-1.08,11.13-3.96,2.48-2.24,4.37-5.75,4.7-8.76l.06-.55h-16.42v13.31Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#121c30"
                        className="cls-2 letter-h"
                        d="M132.15,39.17v-1.27c0-5.08-1.48-8.96-4.41-11.52-3.63-3.17-8.77-3.89-12.44-3.93h-.51s0,16.71,0,16.71h-5.46V6h5.46v10.98h.5c4.6.05,11.11,1,16.01,5.26l.83.72.02-1.08V6h5.46v33.17h-5.46Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#121c30"
                        className="cls-2 letter-h2"
                        d="M23.31,39.17v-16.72h-.51c-3.67.05-8.81.76-12.44,3.93-2.89,2.53-4.37,6.35-4.41,11.35v1.44H.5V6h5.46v16.86l.79-.58c4.95-4.3,11.46-5.25,16.06-5.29h.5V6h5.46v33.17h-5.46Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#121c30"
                        className="cls-2 letter-e"
                        d="M144.78,39.17V6h24.62v5.29h-19.15v12.38l.78-.54c4.71-3.26,11.19-4.52,15.82-4.63l.04,5.47c-2.95.14-5.87.68-8.68,1.61-4.83,1.69-7.56,4.47-7.92,8.05l-.05.55h19.16v5h-24.62Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#121c30"
                        className="cls-2 letter-y"
                        d="M47.02,39.17v-8.73c0-3.56.55-6.89,1.63-9.9l.08-.22-.11-.2c-3.36-5.9-7.16-8.5-12.66-8.65v-5.47c6.34.13,11.37,2.81,15.34,8.18l.39.53.4-.53c.46-.6.94-1.16,1.43-1.68,3.75-4.03,9.03-6.39,14.53-6.51v5.47c-10.22.3-15.56,9.71-15.56,18.96v8.73h-5.46Z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#121c30"
                        className="cls-2 letter-n"
                        d="M176.58,39.17V6h5.52c.68,5.4,2.21,10.63,4.53,15.55,2.31,4.93,6.31,10.98,12.24,11.73l.56.07V6h5.46v33.16h-5.96c-7.79-.57-13-7.25-15.99-12.75l-.94-1.72v14.47h-5.42Z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      fill="#121C30"
                      className="cls-1 letter-t"
                      d="M211.06,3.57h-.6V.52h-1.31v-.52h3.22v.52h-1.31v3.04Z"
                    />
                    <path
                      fill="#121C30"
                      className="cls-1 letter-m"
                      d="M215.29,3.57l-.98-1.89c-.11-.32-.21-.6-.28-.87.02.31.03.65.03.92v1.84h-.57V0h.74l1.04,2.06c.13.37.15.41.25.74.13-.38.18-.52.25-.73l1.04-2.05h.74v3.55h-.57v-1.84c0-.29.01-.59.03-.92-.1.34-.18.58-.31.92l-.95,1.84h-.47Z"
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_2"
                  data-name="Layer 2"
                  viewBox="0 0 35.51 41.43"
                  className="logo-mob animate"
                >
                  <g id="Layer_1-2" data-name="Layer 1">
                    <g xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <rect
                          className="cls-1 letter-e"
                          x="28.17"
                          y=".5"
                          width="6.84"
                          height="40.43"
                          fill="#fff"
                        />
                      </g>
                      <g>
                        <path
                          className="cls-1 letter-p"
                          fill="#fff"
                          d="M.5,40.93V.5h6.84v20.45l.83-.71c3.66-3.02,8.03-5,12.68-5.78v6.98c-3,.64-5.82,2-8.19,3.95-3.47,3.04-5.26,7.6-5.32,13.58v1.96H.5Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            )}
          </Link>
        </span>
        {/* </motion.span> */}
        <div>
          <Link
            className="header-design"
            to="/contact/"
            style={{ color: props.color }}
          >
            Design Studio In Derby
          </Link>
          <HeaderMenuToggle
            color={props.color}
            openState={menuState}
            update={updateState}
          />
        </div>
      </div>
      <HeaderMenu openState={menuState} update={updateState} />
    </header>
  );
}

export default Header;
