import SEO from "../SEO";
import "../Components/NewsPage/NewsPage.css";
import { useEffect, useState } from "react";
import NewsCategoryOption from "../Components/NewsPage/NewsCategoryOption";
import NewsPageItems from "../Components/NewsPage/NewsPageItems";
import Loading from "../Components/Universal/SingleElements/Loading/Loading";
import axios from "axios";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";

function News() {
  const [allCat, setAllCat] = useState();
  const [loaded, setLoaded] = useState(false);
  const [selectedCat, setSelectedCat] = useState("");

  useEffect(() => {
    const catList = [];
    const postData = {
      content_type: "blogPost",
      select: "sys.id,fields.blogCategory",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((entry) => {
        entry.data.items.forEach((item) => {
          if (!catList.find((e) => e.name === item.fields.blogCategory[0])) {
            catList.push({
              name: item.fields.blogCategory[0],
              id: item.sys.id,
            });
          }
        });
        setAllCat(catList);
        setLoaded(true);
        ScrollTop();
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function catChange(e) {
    const allButtons = document.querySelectorAll(".categories-options button");
    allButtons.forEach((b) => {
      b.classList.remove("active");
    });
    e.target.classList.add("active");
    if (e.target.textContent === "All") {
      setSelectedCat("");
    } else {
      setSelectedCat(e.target.textContent);
    }
  }

  if (loaded) {
    return (
      <div>
        <SEO
          title="Hyphen Creative | News"
          description="Hyphen Creative regularly update our blog to bring you the latest industry news and design trends. Derby, Nottingham, Sheffield & Leicester."
          keywords="Web, Design, Development, News, Blog, Tech"
        />
        <section id="news-hero">
          <div className="container-lg">
            <div className="news-hero">
              <h1>Latest News & Shenanigans</h1>
              <div className="categories-options">
                <NewsCategoryOption
                  className="active"
                  key="0"
                  name="All"
                  selected={(e) => {
                    catChange(e);
                  }}
                />
                {allCat.map((cat) => (
                  <NewsCategoryOption
                    key={cat.id}
                    name={cat.name}
                    selected={(e) => {
                      catChange(e);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
        <section id="news-items">
          <div className="container-lg">
            <NewsPageItems cat={selectedCat} />
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div>
        <SEO
          title="News"
          description="Hyphen Creative regularly update our blog to bring you the latest industry news and design trends. Derby, Nottingham, Sheffield & Leicester."
          keywords="Web, Design, Development, News, Blog, Tech"
        />
        <section id="news-hero">
          <div className="container-lg">
            <div className="news-hero">
              <h1>Latest News & Shenanigans</h1>
            </div>
          </div>
        </section>
        <Loading />
      </div>
    );
  }
}

export default News;
