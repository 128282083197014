import SEO from "../SEO";
import { Link } from "react-router-dom";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";
import "../Components/AboutPage/AboutPage.css";
import ProudlyTrustedBy from "../Components/Universal/ProudlyTrustedBy/ProudlyTrustedBy";
// import ResponsiveImage from "../Components/Universal/SingleFunctions/ResponsiveImage";
import HeroL from "../Components/AboutPage/AboutPageImages/about-hero.webp";
// import HeroS from "../Components/AboutPage/AboutPageImages/about-hero-res.webp";
import Listen from "../Components/AboutPage/AboutPageImages/we-listen.svg";
import ListenAlt from "../Components/AboutPage/AboutPageImages/we-listen-2.svg";
import Stephen from "../Components/AboutPage/AboutPageImages/stephen-kay.svg";
import Open from "../Components/Footer/FooterImages/open-accordion.svg";
import Close from "../Components/Footer/FooterImages/close-accordion.svg";
import AboutStephen from "../Components/AboutPage/AboutPageImages/about-stephen.webp";
import AboutKieran from "../Components/AboutPage/AboutPageImages/about-kieran.webp";
import AboutHarry from "../Components/AboutPage/AboutPageImages/about-harry.webp";
import Circle from "../Components/Universal/Svgs/Circle";
import StatsQuoteImage from "../Components/AboutPage/AboutPageImages/about-stats-quote-intro.webp";
import StephenSm from "../Components/AboutPage/AboutPageImages/Stephen.webp";
import HarrySm from "../Components/AboutPage/AboutPageImages/Harry.webp";
import ShopifyPartner from "../Components/ContactPage/ContactPageImages/Shopify-Partner.svg";
import Top3 from "../Components/ContactPage/ContactPageImages/Top-3-Web-Designer.webp";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import axios from "axios";
import ScrollIntoView from "../Components/Universal/SingleFunctions/ScrollIntroView";
import HomeCarousel from "../Components/HomePage/HomeCarousel";

const toggleAccordion = (item) => {
  if (item.classList.contains("open")) {
    item.querySelector(".accordion-container").style.height = `0px`;
    item.classList.remove("open");
  } else {
    item.querySelector(".accordion-container").style.height = `${
      item.querySelector(".accordion-text").getBoundingClientRect().height
    }px`;
    item.classList.add("open");
  }
};

const dot = document.createElement("span");
dot.classList.add("dot");

function About() {
  const [carousel, setCarousel] = useState();

  useEffect(() => {
    ScrollIntoView(
      document.querySelectorAll(".about-services .each-accordion"),
      0
    );
    ScrollIntoView(document.querySelectorAll(".team-members > div > *"), 0);
    ScrollIntoView(
      document.querySelectorAll(".about-stats-quote .stats .stat"),
      0
    );
    ScrollIntoView(document.querySelectorAll(".about-final-text > div > *"), 0);

    const postData = {
      content_type: "homePage",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((entry) => {
        setCarousel(entry.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <SEO
        title="Hyphen Creative | About"
        description="Hyphen Group are a Marketing and a Creative Design Agency based in Derby, UK. Hyphen Creative cover all design services and various Marketing strategies."
        keywords="Web, Design, Development, News, Blog, Tech"
      />
      <section id="about-hero">
        <div className="container">
          <div className="about-hero">
            <div className="intro">
              <h1>
                We're a small creative agency with{" "}
                <span>
                  <Circle stroke="6" />
                  big
                </span>{" "}
                agency ideas
              </h1>
              <p>
                Since our formation our main aim has been to transform the
                common impression of modern-day design and marketing agencies,
                we've worked to develop a creative culture that thrives in each
                department.
              </p>
            </div>
            <div className="stats">
              <div className="stat">
                <div className="icon">🫡</div>
                <div>
                  <p className="figure">10+</p>
                  <p>Years Experience</p>
                </div>
              </div>
              <div className="stat">
                <div className="icon">😊</div>
                <div>
                  <p className="figure">235</p>
                  <p>Happy Clients</p>
                </div>
              </div>
              <div className="stat">
                <div className="icon">🙏</div>
                <div>
                  <p className="figure">2000+</p>
                  <p>Projects Completed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProudlyTrustedBy />
      <section id="about-hero-image">
        <img
          fetchpriority="high"
          src={HeroL}
          alt="Working"
          width="1810px"
          height="1080px"
        />
      </section>

      <section className="big-ideas">
        <div className="container">
          <div className="agency-title">
            <h2 className="bold">
              A small agency <br />
              with big ideas
            </h2>
            <div>
              <img
                width="301.969px"
                height="137.923px"
                src={Listen}
                alt="We Listen"
              />
            </div>
          </div>

          <div className="agency-text">
            <p>
              Our creative agency is made up of a team full of passion and
              desire to deliver results that leave a lasting impression.
            </p>
            <p>
              We wanted to work together as an agency, not for an agency. We
              said goodbye to hierarchy, we left behind office politics, and we
              created something truly special.
            </p>
            <div className="awards">
              <img
                width="212.77px"
                height="36.794px"
                src={ShopifyPartner}
                alt="Shopify Partner"
              />
              <img
                width="71px"
                height="71px"
                src={Top3}
                alt="Top 3 Website Designer 2021"
              />
            </div>
          </div>
        </div>
      </section>

      {carousel ? <HomeCarousel images={carousel[0].fields.carousel} /> : ""}

      <section className="ste-quote">
        <div className="container">
          <p>
            Hyphen Creative was born out of a passion for design and web
            development. Our creative services have grown year on year as our
            team continues to develop. Our passion for all things design gives
            us the motivation to strive for the best for all of our clients, big
            or small.
          </p>
          <img
            width="153.866px"
            height="36.391px"
            src={Stephen}
            alt="Stephen Kay"
          />
          <p>Co-founder</p>
        </div>
      </section>

      <section className="about-services">
        <div className="container">
          <div className="about-serv-title">
            <h2>
              <b>A small agency</b> <br />
              with big ideas <span style={{ marginLeft: "10px" }}>✨</span>
            </h2>
            <p>
              Our clients range from small local businesses to large global
              brands - each one treated with as much love as the next. Speak to
              any of our clients and they will say the same thing{" "}
              <span className="jeff">“They will go the extra mile”</span>{" "}
              something of which we are extremely proud of as a creative agency.
            </p>
          </div>
          <div className="about-serv-text">
            <img
              width="306.742px"
              height="109.313px"
              src={ListenAlt}
              alt="Stephen Kay"
            />

            <div className="cta-drops about-drops">
              <div className="accordion">
                <div className="each-accordion">
                  <button
                    className="accordion-toggle p-lg"
                    onClick={(e) => {
                      toggleAccordion(e.target.closest(".each-accordion"));
                    }}
                  >
                    Logo & Brand Design
                    <span className="open">
                      <img width="14px" height="14px" src={Open} alt="Plus" />
                    </span>
                    <span className="close">
                      <img width="14px" height="2px" src={Close} alt="Minus" />
                    </span>
                  </button>
                  <div className="accordion-container">
                    <div className="accordion-text">
                      <p>
                        We help our clients to create super-effective brand
                        strategies and marketing collaterals through bespoke
                        <Link to="/service/design" onClick={ScrollTop}>
                          graphic design
                        </Link>
                        . So whether it's a flyer, a set of beautiful business
                        cards or stunning{" "}
                        <Link
                          to="/service/packaging-design"
                          onClick={ScrollTop}
                        >
                          packaging design
                        </Link>
                        for a new product we'd love to help.
                      </p>
                      <Link to="/service/branding">
                        <p className="underline-link">Find Out More</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="each-accordion">
                  <button
                    className="accordion-toggle p-lg"
                    onClick={(e) => {
                      toggleAccordion(e.target.closest(".each-accordion"));
                    }}
                  >
                    Graphic Design
                    <span className="open">
                      <img width="14px" height="14px" src={Open} alt="Plus" />
                    </span>
                    <span className="close">
                      <img width="14px" height="2px" src={Close} alt="Minus" />
                    </span>
                  </button>
                  <div className="accordion-container">
                    <div className="accordion-text">
                      <p>
                        We offer a whole range of design services covering
                        everything from brand stationery to large format
                        exhibition graphics.
                      </p>
                      <Link to="/service/design">
                        <p className="underline-link">Find Out More</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="each-accordion">
                  <button
                    className="accordion-toggle p-lg"
                    onClick={(e) => {
                      toggleAccordion(e.target.closest(".each-accordion"));
                    }}
                  >
                    Brand Strategy
                    <span className="open">
                      <img width="14px" height="14px" src={Open} alt="Plus" />
                    </span>
                    <span className="close">
                      <img width="14px" height="2px" src={Close} alt="Minus" />
                    </span>
                  </button>
                  <div className="accordion-container">
                    <div className="accordion-text">
                      <p>
                        We develop brand strategies to help you to discover the
                        best way to show off your brand to your existing and
                        potential customers.
                      </p>
                      <Link to="/service/brand-strategy/">
                        <p className="underline-link">Find Out More</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="each-accordion">
                  <button
                    className="accordion-toggle p-lg"
                    onClick={(e) => {
                      toggleAccordion(e.target.closest(".each-accordion"));
                    }}
                  >
                    Web Design
                    <span className="open">
                      <img width="14px" height="14px" src={Open} alt="Plus" />
                    </span>
                    <span className="close">
                      <img width="14px" height="2px" src={Close} alt="Minus" />
                    </span>
                  </button>
                  <div className="accordion-container">
                    <div className="accordion-text">
                      <p>
                        From simple one-page apps to 100 page{" "}
                        <Link to="/service/ecommerce" onClick={ScrollTop}>
                          eCommerce
                        </Link>{" "}
                        sites we cover it all. Our team of developers are
                        skilled in PHP, Javascript and WordPress websites.
                      </p>
                      <Link to="/service/web-design">
                        <p className="underline-link">Find Out More</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="each-accordion">
                  <button
                    className="accordion-toggle p-lg"
                    onClick={(e) => {
                      toggleAccordion(e.target.closest(".each-accordion"));
                    }}
                  >
                    Digital Marketing
                    <span className="open">
                      <img width="14px" height="14px" src={Open} alt="Plus" />
                    </span>
                    <span className="close">
                      <img width="14px" height="2px" src={Close} alt="Minus" />
                    </span>
                  </button>
                  <div className="accordion-container">
                    <div className="accordion-text">
                      <p>
                        Digital marketing is becoming a must for most
                        businesses, with more and more competition popping up
                        every day, how do you get ahead?
                      </p>
                      <Link to="/service/marketing/">
                        <p className="underline-link">Find Out More</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="each-accordion">
                  <button
                    className="accordion-toggle p-lg"
                    onClick={(e) => {
                      toggleAccordion(e.target.closest(".each-accordion"));
                    }}
                  >
                    Social Media
                    <span className="open">
                      <img width="14px" height="14px" src={Open} alt="Plus" />
                    </span>
                    <span className="close">
                      <img width="14px" height="2px" src={Close} alt="Minus" />
                    </span>
                  </button>
                  <div className="accordion-container">
                    <div className="accordion-text">
                      <p>
                        We will develop a detailed set of brand communication
                        guidelines - giving you the freedom to create knowing
                        everything will look on-brand.
                      </p>
                      <Link to="/service/social-media-advertising">
                        <p className="underline-link">Find Out More</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="each-accordion">
                  <button
                    className="accordion-toggle p-lg"
                    onClick={(e) => {
                      toggleAccordion(e.target.closest(".each-accordion"));
                    }}
                  >
                    Shopify Websites
                    <span className="open">
                      <img width="14px" height="14px" src={Open} alt="Plus" />
                    </span>
                    <span className="close">
                      <img width="14px" height="2px" src={Close} alt="Minus" />
                    </span>
                  </button>
                  <div className="accordion-container">
                    <div className="accordion-text">
                      <p>
                        We develop modern Shopify websites that will showcase
                        your products beautifully. Get in touch now to discuss
                        your website.
                      </p>
                      <Link to="/service/ecommerce">
                        <p className="underline-link">Find Out More</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="team">
        <div className="container">
          <div className="team-title">
            <h2>Our leadership team</h2>
            <p>
              Our team has shaped into a passionate, diverse group with a unique
              desire to achieve. Every member of our team has the same love for
              this amazing job is involved in each project to ensure we achieve
              the best results we can for each and every one of our clients.
            </p>
          </div>
          <div className="team-members">
            <div>
              <img
                width="589px"
                height="724px"
                className="member-img"
                src={AboutStephen}
                alt="Stephen"
              />
              <h3>Stephen Kay</h3>
              <p>Co-founder/Designer</p>
            </div>
            <div>
              <img
                width="589px"
                height="724px"
                className="member-img"
                src={AboutHarry}
                alt="Harry"
              />
              <h3>Harry Boyd</h3>
              <p>Co-founder</p>
            </div>
            <div>
              <img
                width="589px"
                height="724px"
                className="member-img"
                src={AboutKieran}
                alt="Kieran"
              />
              <h3>Kieran McIntosh</h3>
              <p>Lead Web Developer</p>
            </div>
          </div>
        </div>
      </section>

      <section id="about-stats-quote">
        <div className="container">
          <div className="about-stats-quote-intro">
            <h2>
              <span className="bold">A small agency</span> with{" "}
              <span>
                <Circle stroke="7" />
                big
              </span>{" "}
              ideas.
            </h2>
            <p>
              Our team has shaped into a passionate, diverse group with a unique
              desire to achieve. Every member of our team has the same love for
              this amazing job is involved in each project to ensure we achieve
              the best results we can for each and every one of our clients.
            </p>
            <img
              width="1350px"
              height="529.281px"
              src={StatsQuoteImage}
              alt="Placeholder"
            />
          </div>
          <div className="about-stats-quote">
            <div className="stats">
              <div className="stat">
                <h3 className="bold">2017</h3>
                <p>
                  Hyphen was launched in Belper, Derbyshire by founders Harry &
                  Stephen.
                </p>
              </div>
              <div className="stat">
                <h3 className="bold">2000+</h3>
                <p>
                  <Link to="/service/branding" onClick={ScrollTop}>
                    Branding
                  </Link>
                  ,{" "}
                  <Link to="/service/web-design" onClick={ScrollTop}>
                    website design
                  </Link>
                  ,{" "}
                  <Link to="/service/web-development/" onClick={ScrollTop}>
                    development
                  </Link>{" "}
                  and{" "}
                  <Link to="/service/print-design" onClick={ScrollTop}>
                    print
                  </Link>{" "}
                  projects delivered.
                </p>
              </div>
              <div className="stat">
                <h3 className="bold">Small</h3>
                <p>
                  We are a small team with big ambition that put our customers
                  at the forefront of everything we do.
                </p>
              </div>
              <div className="stat">
                <h3 className="bold">1</h3>
                <p>
                  We all have 1 common goal - achieve the best outcomes possible
                  for all clients, large and small.
                </p>
              </div>
            </div>
            <div className="quote">
              <Carousel
                showStatus={false}
                showIndicators={false}
                renderThumbs={(images) =>
                  images.map((image, index) => (
                    <span key={index} className="dot" />
                  ))
                }
                showArrows={false}
              >
                <div className="single">
                  <p>
                    We founded Hyphen to transform visions into captivating
                    realities, merging creativity with top-notch service to
                    craft meaningful results for our clients.
                  </p>
                  <p className="author">
                    <img
                      width="112px"
                      height="110px"
                      src={StephenSm}
                      alt="Stephen Kay Icon"
                    />
                    <b>
                      Stephen Kay <br />
                      Co-founder
                    </b>
                  </p>
                </div>
                <div className="single">
                  <p>
                    We wanted to redefine value through not only our work but
                    also the customer experience, ensuring every client feels
                    valued and satisfied with all aspects of our service.
                  </p>
                  <p className="author">
                    <img
                      width="112px"
                      height="110px"
                      src={HarrySm}
                      alt="Harry Boyd Icon"
                    />
                    <b>
                      Harry Boyd <br />
                      Co-founder
                    </b>
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section id="about-final-text">
        <div className="container">
          <div className="about-final-text">
            <p className="p-lg">
              We created a team where our clients come first, where your
              interests are in our best interest, where we feel like your
              plug-in team. We started in a home office, and now we call our
              office in the historic HSBC bank home, all thanks to our clients
              appreciating us.
            </p>
            <div>
              <h3>Passion 😍</h3>
              <p>
                We are passionate about all things design and marketing and go
                the extra mile to ensure all of our clients are happy.
              </p>
              <h3>Dedication 🏃‍♀️</h3>
              <p>
                We make sure all of our clients are treated with the same
                top-notch service, from SMEs to international brands we take
                care of everybody.
              </p>
              <h3>Emotion 🌈</h3>
              <p>
                We see every new day as a perfect opportunity to learn, to
                develop as a team and show you our desire to create knock-out
                designs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
