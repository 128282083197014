import Car from "./HomePageImages/Car.webp";
import Case from "./HomePageImages/case.webp";
import Circle from "../Universal/Svgs/Circle";
import { Link } from "react-router-dom";

function HomeSplitText() {
  return (
    <div className="split-text-con">
      <div className="container pd-t-xl">
        <div className="split-text third">
          <p className="p-lg">
            We're a
            <span className="jeff"> passionate {<Circle stroke="5" />}</span>
            bunch of creatives and developers who strive to achieve for our
            clients,{" "}
            <img
              loading="lazy"
              width="30px"
              height="27px"
              src={Case}
              alt="Case"
            />{" "}
            big or{" "}
            <img
              className="car"
              loading="lazy"
              width="55px"
              height="30px"
              src={Car}
              alt="Car"
            />{" "}
            small.
          </p>
          <p className="text-right">
            We're a British-based creative design studio that excels in the
            <Link to="/service/design"> design</Link>, and deployment of
            innovative cross-platform brand experiences. Our team delivers{" "}
            <Link to="/service/web-design">web design</Link>,{" "}
            <Link to="/service/branding">brand strategy</Link> and digital
            <Link to="/service/marketing"> marketing services</Link> across the
            world for clients such as large corporate clients and small
            independent traders.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeSplitText;
