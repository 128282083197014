import WomanWorking from "./HomePageImages/woman-working.svg";
import ManWorking from "./HomePageImages/man-working.svg";
import WeListen from "./HomePageImages/We-Listen.svg";
import Cirle from "../Universal/Svgs/Circle";

function HomeHero() {
  return (
    <section id="HomeHero">
      <div className="container">
        <p className="say-no">
          Say <span className="bold">no</span> to mediocre
        </p>
        <div className="home-hero">
          <h1>
            <span className="we-care">
              Empowering{" "}
              <span className="jeff">
                <Cirle stroke="6" />
                We care
              </span>
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="127.703"
              height="87.085"
              viewBox="0 0 127.703 87.085"
              className="heart-loop"
            >
              <path
                id="Path_34250"
                data-name="Path 34250"
                d="M810.171,1622.659c17.437,5.3,36.474-3.656,49.23-16.673,11.185-11.414,19.187-26.528,19.686-42.5.172-5.489-.642-11.276-3.927-15.676s-9.573-6.886-14.513-4.489c-7.6,3.686-6.963,14.561-5.157,22.811a19.038,19.038,0,0,0-17.12-10.909,7,7,0,0,0-3.557.8,6.858,6.858,0,0,0-2.494,3.141c-2.9,6.3-1.492,14.019,2.425,19.741s10.017,9.644,16.45,12.234a56.846,56.846,0,0,0,76.037-37.633"
                transform="translate(-807.052 -1539.657)"
                fill="none"
                stroke="#0f1b30"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="5"
              />
              <path
                id="Path_34251"
                data-name="Path 34251"
                d="M933.129,1553.663a12.157,12.157,0,0,1-9.685,7.88c5.961-5.319,11.439-11.673,13.471-19.4a104.181,104.181,0,0,0-2.115,19.092c-.085,4.724.229,9.707,2.785,13.68"
                transform="translate(-813.337 -1539.644)"
                fill="none"
                stroke="#0f1b30"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="5"
              />
            </svg>{" "}
            <span className="we-listen">
              brands,{" "}
              <img fetchpriority="high" src={WeListen} alt="We Listen" />
            </span>
            <br />
            <span className="bold">
              big
              <img
                fetchpriority="high"
                height="125px"
                width="125px"
                className="woman"
                src={WomanWorking}
                alt="Woman Working"
              />
              &
              <img
                fetchpriority="high"
                height="125px"
                width="125px"
                className="man"
                src={ManWorking}
                alt="Man Working"
              />
              small,
            </span>{" "}
            across <br />
            the{" "}
            <span className="globe">
              globe.
              <span className="we-create">{"<WeCreate/>"}</span>
              <div className="globe-container">
                <div className="countries-container">
                  <svg
                    className="countries"
                    width="246"
                    height="60"
                    viewBox="0 0 246 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M39 0V6H42V9H36V12H33V15H24V9H21V6H12V9H9V3H6V6H0V9H3V12H12V15H15V24H18V27H21V30H24V33H27V42H30V45H33V48H36V60H39V57H42V51H45V42H48V39H45V36H36V33H27V27H30V24H36V15H42V9H45V6H48V3H51V0H39Z"
                      fill="#0f1b30"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M90 3V9H84V6H75V9H72V6H63V9H72V12H69V15H63V12H57V24H60V27H57V30H54V33H60V36H63V45H66V51H69V48H72V45H75V30H72V27H81V30H87V33H90V30H93V33H96V36H99V27H102V21H105V15H108V9H126V12H135V15H138V24H141V27H144V30H147V33H150V42H153V45H156V48H159V60H162V57H165V51H168V42H171V39H168V36H159V33H150V27H153V24H159V15H165V9H168V6H171V3H174V0H162V6H165V9H159V12H156V15H147V9H144V6H135V9H132V3H129V6H117V3H90ZM60 24V21H63V18H66V21H69V18H81V24H78V21H72V24H60Z"
                      fill="#0f1b30"
                    />
                    <path d="M108 19H111V25H105V22H108V19Z" fill="#0f1b30" />
                    <path d="M96 39H99V42H96V39Z" fill="#0f1b30" />
                    <path
                      d="M111 42H108V39H105V42H108V45H111V42Z"
                      fill="#0f1b30"
                    />
                    <path
                      d="M111 48V51H114V57H108V54H102V51H105V48H111Z"
                      fill="#0f1b30"
                    />
                    <path d="M102 54V57H99V54H102Z" fill="#0f1b30" />
                    <path d="M75 48H78V51H75V48Z" fill="#0f1b30" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M186 12H180V24H183V27H180V30H177V33H183V36H186V45H189V51H192V48H195V45H198V30H195V27H204V30H210V33H213V30H216V33H219V36H222V27H225V21H228V15H231V9H246V6H240V3H213V9H207V6H198V9H195V6H186V9H195V12H192V15H186V12ZM183 24V21H186V18H189V21H192V18H204V24H201V21H195V24H183Z"
                      fill="#0f1b30"
                    />
                    <path d="M231 19H234V25H228V22H231V19Z" fill="#0f1b30" />
                    <path d="M219 39H222V42H219V39Z" fill="#0f1b30" />
                    <path
                      d="M234 42H231V39H228V42H231V45H234V42Z"
                      fill="#0f1b30"
                    />
                    <path
                      d="M234 48V51H237V57H231V54H225V51H228V48H234Z"
                      fill="#0f1b30"
                    />
                    <path d="M225 54V57H222V54H225Z" fill="#0f1b30" />
                    <path d="M198 48H201V51H198V48Z" fill="#0f1b30" />
                  </svg>
                </div>
                <svg
                  className="globe"
                  width="66"
                  height="63"
                  viewBox="0 0 66 63"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30 0H27H24V3H21H18H15V6H12V9H9V12H6V15H3V18V21H0V24V27V30V33V36V39V42H3V45L3 48H6L6 51H9V54H12V57H15V60H18H21H24V63H27H30H33H36H39H42V60H45H48H51V57H54V54H57V51H60V48H63V45V42H66V39V36V33V30V27V24V21H63V18V15H60V12H57V9H54V6H51V3H48H45H42V0H39H36H33H30ZM42 3V6H45H48H51V9H54V12V15H57H60V18V21H63V24V27V30V33V36V39V42H60V45V48H57H54V51H51V54V57H48H45H42V60H39H36H33H30H27H24V57H21H18H15V54V51H12V48H9H6V45V42H3L3 39V36V33V30V27V24V21H6V18H9V15V12H12V9H15V6H18H21H24V3H27H30H33H36H39H42Z"
                    fill="#0f1b30"
                  />
                </svg>
              </div>
            </span>
          </h1>
        </div>
      </div>
    </section>
  );
}

export default HomeHero;
