import { Link } from "react-router-dom";
import ScrollTop from "../SingleFunctions/ScrollTop";
import "./CollectionPosts.css";
import ConvertContentfulDate from "../SingleFunctions/ConvertContentfulDate";

function CollectionPost(props) {
  const date = ConvertContentfulDate(props.date);
  return (
    <Link
      onClick={ScrollTop}
      to={"/post/" + props.slug}
      state={{ data: props }}
    >
      <div
        className="blog-post"
        style={{
          background:
            "url(" +
            props.image +
            "), linear-gradient(180deg, rgba(0,0,0,0.020045518207282953) 50%, rgba(15,27,48,1) 100%)",
        }}
      >
        <div className="text">
          <p>{date}</p>
          <h3>{props.title}</h3>
          <p>{props.tags.map((tag) => tag + " ")}</p>
        </div>
      </div>
    </Link>
  );
}

export default CollectionPost;
