import "./HeaderMenuToggle.css";

function HeaderMenuToggle(props) {
  return (
    <button
      type="button"
      aria-label="Open Menu"
      className={"header-menu-toggle " + props.openState}
      onClick={props.update}
    >
      <span style={{ backgroundColor: props.color }} className="top" />
      <span style={{ backgroundColor: props.color }} className="bottom" />
    </button>
  );
}

export default HeaderMenuToggle;
