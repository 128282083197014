import SEO from "../SEO";

function PrivacyPolicy() {
  return (
    <div className="container">
      <SEO
        title="Hyphen Creative | Terms & Conditions"
        description="Hyphen Creative are British-based design agency that excels in the design, and deployment of innovative cross-platform brand experiences."
        keywords="Hyphen Creative, Midlands Design Agency, Belper, Derby, Nottingham, Design, Websites, Web design, Development, Branding, Photography, Advertising, SEO Company, Print, Marketing Company, Graphic"
      />
      <h1>Welcome to our Privacy Policy</h1>

      <p>Your privacy is critically important to us.</p>
      <p>Hyphen Creative is located at:</p>

      <p>
        Suite 2 Belper North Mill <br />
        Derbyshire, England <br />
      </p>

      <p>
        It is Hyphen Creative's policy to respect your privacy regarding any
        information we may collect while operating our website. This Privacy
        Policy applies to https://hyphencreative.co.uk (hereinafter, “us”, “we”,
        or “https://hyphencreative.co.uk”). We respect your privacy and are
        committed to protecting personally identifiable information you may
        provide us through the Website. We have adopted this privacy policy
        (“Privacy Policy”) to explain what information may be collected on our
        Website, how we use this information, and under what circumstances we
        may disclose the information to third parties. This Privacy Policy
        applies only to information we collect through the Website and does not
        apply to our collection of information from other sources.
      </p>
      <p>
        This Privacy Policy, together with the Terms and conditions posted on
        our Website, set forth the general rules and policies governing your use
        of our Website. Depending on your activities when visiting our Website,
        you may be required to agree to additional terms and conditions.
      </p>

      <h2>Website Visitors</h2>
      <p>
        Like most website operators, Hyphen Creative collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. Hyphen Creative's purpose in collecting non-personally
        identifying information is to better understand how Hyphen Creative's
        visitors use its website. From time to time, Hyphen Creative may release
        non-personally-identifying information in the aggregate, e.g., by
        publishing a report on trends in the usage of its website.
      </p>

      <p>
        Hyphen Creative also collects potentially personally-identifying
        information like Internet Protocol (IP) addresses for logged in users
        and for users leaving comments on https://hyphencreative.co.uk blog
        posts. Hyphen Creative only discloses logged in user and commenter IP
        addresses under the same circumstances that it uses and discloses
        personally-identifying information as described below.
      </p>

      <h2>Gathering of Personally-Identifying Information</h2>
      <p>
        Certain visitors to Hyphen Creative's websites choose to interact with
        Hyphen Creative in ways that require Hyphen Creative to gather
        personally-identifying information. The amount and type of information
        that Hyphen Creative gathers depends on the nature of the interaction.
        For example, we ask visitors who sign up for a blog at
        https://hyphencreative.co.uk to provide a username and email address.
      </p>

      <h2>Security</h2>

      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>

      <h2>Links To External Sites</h2>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        and terms and conditions of every site you visit.
      </p>
      <p>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites, products or
        services.
      </p>

      <h2>https://hyphencreative.co.uk uses Google AdWords for re-marketing</h2>
      <p>
        https://hyphencreative.co.uk uses the re-marketing services to advertise
        on third party websites (including Google) to previous visitors to our
        site. It could mean that we advertise to previous visitors who haven't
        completed a task on our site, for example using the contact form to make
        an enquiry. This could be in the form of an advertisement on the Google
        search results page, or a site in the Google Display Network.
        Third-party vendors, including Google, use cookies to serve ads based on
        someone's past visits. Of course, any data collected will be used in
        accordance with our own privacy policy and Google's privacy policy.
      </p>

      <p>
        You can set preferences for how Google advertises to you using the
        Google Ad Preferences page, and if you want to you can opt out of
        interest-based advertising entirely by cookie settings or permanently
        using a browser plugin.
      </p>

      <h2>Aggregated Statistics</h2>
      <p>
        Hyphen Creative may collect statistics about the behaviour of visitors
        to its website. Hyphen Creative may display this information publicly or
        provide it to others. However, Hyphen Creative does not disclose your
        personally-identifying information.
      </p>

      <h2>Cookies</h2>

      <p>
        To enrich and perfect your online experience, Hyphen Creative uses
        “Cookies”, similar technologies and services provided by others to
        display personalised content, appropriate advertising and store your
        preferences on your computer.
      </p>
      <p>
        A cookie is a string of information that a website stores on a visitor's
        computer, and that the visitor's browser provides to the website each
        time the visitor returns. Hyphen Creative uses cookies to help Hyphen
        Creative identify and track visitors, their usage of
        https://hyphencreative.co.uk, and their website access preferences.
        Hyphen Creative visitors who do not wish to have cookies placed on their
        computers should set their browsers to refuse cookies before using
        Hyphen Creative's websites, with the drawback that certain features of
        Hyphen Creative's websites may not function properly without the aid of
        cookies.
      </p>
      <p>
        By continuing to navigate our website without changing your cookie
        settings, you hereby acknowledge and agree to Hyphen Creative's use of
        cookies.
      </p>

      <h2>Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, Hyphen Creative may change
        its Privacy Policy from time to time, and in Hyphen Creative's sole
        discretion. Hyphen Creative encourages visitors to frequently check this
        page for any changes to its Privacy Policy. Your continued use of this
        site after any change in this Privacy Policy will constitute your
        acceptance of such change.
      </p>

      <h2>Human Rights Act</h2>
      <p>
        Human rights are violated by modern slavery. Slavery, servitude,
        compulsory and forced labour, and human trafficking are all forms of
        exploitation involving an individual's liberty being taken by another
        for his or her own profit.
      </p>
      <p>
        In all our business dealings and relationships, we are committed to
        acting ethically and with integrity. We ensure that we implement and
        enforce effective systems and controls to ensure there is no modern
        slavery in the Company or in any of our supply chains.
      </p>
      <p>
        Additionally, we are committed to achieving transparency within our
        organization, as well as showing our commitment to combating modern
        slavery throughout our supply chains, as required by our disclosure
        obligations under the Modern Slavery Act 2015.
      </p>
      <p>
        We apply this policy to all employees or third-parties working for us or
        on our behalf, including all levels of managers, directors, officers,
        agency workers, seconded workers, interns, agents, contractors,
        consultants, and partners.
      </p>
      <p>We may amend it at any time.</p>

      <h2>Responsibility for the policy</h2>
      <p>
        The Company has overall responsibility for ensuring this policy complies
        with our legal and ethical obligations, and that all those under our
        control comply with it. In addition to developing and implementing this
        policy, monitoring its use and effectiveness, and ensuring that the
        internal controls and procedures are effective in combating modern
        slavery, the company is primarily responsible for implementing it.
      </p>
      <p>
        Hyphen Creative Limited is committed to encouraging equality, diversity
        and inclusion among our workforce, and eliminating unlawful
        discrimination.
      </p>
      <p>
        The aim is for our workforce to be truly representative of all sections
        of society and our customers, and for each employee to feel respected
        and able to give their best.
      </p>
      <p>
        The organisation - in providing goods and/or services and/or facilities
        - is also committed against unlawful discrimination of customers or the
        public.
      </p>

      <h2>Our policy's purpose</h2>
      <p>This policy's purpose is to:</p>
      <p>
        Provide equality, fairness and respect for all in our employment,
        whether temporary, part-time or full-time
      </p>
      <p>
        Not unlawfully discriminate because of the Equality Act 2010 protected
        characteristics of:
      </p>
      <ul>
        <li>age</li>
        <li>disability</li>
        <li>gender reassignment</li>
        <li>marriage or civil partnership</li>
        <li>pregnancy and maternity</li>
        <li>
          race (including colour, nationality, and ethnic or national origin)
        </li>
        <li>religion or belief</li>
        <li> sex</li>
        <li>sexual orientation</li>
      </ul>
      <p>
        Oppose and avoid all forms of unlawful discrimination. This includes in:
      </p>
      <ul>
        <li>pay and benefits</li>
      </ul>
    </div>
  );
}

export default PrivacyPolicy;
