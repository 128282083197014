import HenLogoMain from "./HeaderImages/hen-logo.svg";
import Logo from "./HeaderImages/Hyphen.svg";
import GoogleRating from "./HeaderImages/Google-Rating.svg";
import { NavLink } from "react-router-dom";
import "./HeaderMenu.css";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";

function HeaderMenu(props) {
  const links = document.querySelectorAll(".menu-btn");
  let startingDelay = 1;
  links.forEach((link) => {
    link.style.transitionDelay = `${startingDelay}s`;
    startingDelay += 0.3;
    return;
  });

  const currentYear = new Date().getFullYear();

  return (
    <div className={"header-menu " + props.openState}>
      <div className="header-menu__top">
        <picture>
          <source media="(max-width: 767px)" srcSet={Logo} />
          <source media="(min-width: 768px)" srcSet={HenLogoMain} />
          <img
            width="217.557px"
            height="39.668px"
            src={Logo}
            alt="Hyphen Logo"
          />
        </picture>
        <button
          type="button"
          aria-label="Close Menu"
          className={"header-menu__top__close " + props.openState}
          onClick={props.update}
        >
          <span className="top"></span>
          <span className="bottom"></span>
        </button>
      </div>
      <div className="header-menu__links">
        <NavLink
          onClick={() => {
            ScrollTop();
            props.update();
          }}
          to="/"
          className="menu-btn"
        >
          <span>Home</span>
        </NavLink>
        <NavLink
          onClick={() => {
            ScrollTop();
            props.update();
          }}
          to="/projects"
          className="menu-btn"
        >
          <span>Our Work</span>
        </NavLink>
        <NavLink
          onClick={() => {
            ScrollTop();
            props.update();
          }}
          to="/services"
          className="menu-btn"
        >
          <span>Services</span>
        </NavLink>
        <NavLink
          onClick={() => {
            ScrollTop();
            props.update();
          }}
          to="/news"
          className="menu-btn"
        >
          <span>News</span>
        </NavLink>
        <NavLink
          onClick={() => {
            ScrollTop();
            props.update();
          }}
          to="/about"
          className="menu-btn"
        >
          <span>Agency</span>
        </NavLink>
        <NavLink to="https://shop.hyphencreative.co.uk/" className="menu-btn">
          <span>Shop</span>
        </NavLink>
        <NavLink
          onClick={() => {
            ScrollTop();
            props.update();
          }}
          to="/contact"
          className="menu-btn"
        >
          <span>Contact</span>
        </NavLink>
      </div>
      <div className="header-menu__bottom">
        <div>
          <p className="small-print">
            © {currentYear} Hyphen Creative Limited. All rights reserved.
          </p>
          <p className="small-print">
            Powered by Hyphen Creative. Company No. 10716089
          </p>
        </div>
        <a
          href="https://g.page/r/CbTsLjDX86tXEBM/review"
          target="_blank"
          rel="noreferrer"
        >
          <img
            width="301.836px"
            height="44.821px"
            src={GoogleRating}
            alt="Google Rating"
          ></img>
        </a>
      </div>
    </div>
  );
}

export default HeaderMenu;
