import "./HomeClientBoxes.css";
import HomeClientBox from "./HomeClientBox";
import Baler from "./HomePageImages/Baler-Options-Mobile.webp";
import Brand from "./HomePageImages/howardson-cards.webp";
import NHW from "./HomePageImages/odilia-boxes.webp";
import Dennis from "./HomePageImages/gold-course.webp";
import Mill from "./HomePageImages/belper-mill.webp";
import Adidas from "./HomePageImages/adidas-boxes.webp";
import Bowmer from "./HomePageImages/bowmer-boxes.webp";
import Vetcare from "./HomePageImages/vetcare-dog.webp";
import Percys from "./HomePageImages/percys-boxes.webp";
import Specsavers from "./HomePageImages/specsavers-boxes.webp";
import Prym from "./HomePageImages/prym-health-cover.webp";

const HomeClientBoxes = () => {
  return (
    <div className="container-full" id="home-client-boxes">
      <div className="client-boxes">
        <HomeClientBox
          size="large"
          img_l={Baler}
          img_l_px="960"
          img_s={Baler}
          img_s_px="960"
          imgAlt="Baler Options Mobile Website"
          overlay="flex"
          link="/project/baler-options"
          client="Baler Options"
          desc="Design, Development..."
        />
        <div className="client-boxes half">
          <HomeClientBox
            size="small"
            img_l={Brand}
            img_l_px="480"
            img_s={Brand}
            img_s_px="480"
            imgAlt="Branding"
            overlay="flex"
            link="/project/branding"
            client="Branding"
            desc="All Things Branding..."
          />
          <HomeClientBox
            size="small"
            img_l={Dennis}
            img_l_px="480"
            img_s={Dennis}
            img_s_px="480"
            imgAlt="Dennis"
            overlay="flex"
            link="/project/dennis-group"
            client="Dennis"
            desc="Design, Print..."
          />
          <HomeClientBox
            size="landscape"
            img_l={NHW}
            img_l_px="960"
            img_s={NHW}
            img_s_px="960"
            imgAlt="OdiliaClark"
            overlay="flex"
            link="/project/odiliaclark"
            client="OdiliaClark"
            desc="Branding, Design, Print, Videography..."
          />
        </div>
        <div className="client-boxes half">
          <HomeClientBox
            size="portrait"
            img_l={Mill}
            img_l_px="480"
            img_s={Mill}
            img_s_px="480"
            imgAlt="Belper Mill"
            overlay="flex"
            link="/project/best-of-south-west-ldn"
            client="Best of South West LDN"
            desc="Branding, Design, Development..."
          />
          <div className="client-boxes half block">
            <HomeClientBox
              size="full"
              img_l={Adidas}
              img_l_px="480"
              img_s={Adidas}
              img_s_px="480"
              imgAlt="adidas"
              overlay="flex"
              link="/project/adidas"
              client="adidas"
              desc="Design..."
            />
            <HomeClientBox
              size="full"
              img_l={Bowmer}
              img_l_px="480"
              img_s={Bowmer}
              img_s_px="480"
              imgAlt="Bowmer & Kirkland"
              overlay="flex"
              link="/project/bowmer-kirkland"
              client="Bowmer & Kirkland"
              desc="Branding, Design, Development, Print, Packaging, Social..."
            />
          </div>
        </div>
        <div className="client-boxes half">
          <HomeClientBox
            size="full"
            img_l={Vetcare}
            img_l_px="960"
            img_s={Vetcare}
            img_s_px="960"
            imgAlt="Vetcareathome"
            overlay="flex"
            link="/project/vetcare-at-home"
            client="Vetcare"
            desc="Signage, Print, Photography, Website..."
          />
        </div>
        <div className="client-boxes">
          <HomeClientBox
            size="small"
            img_l={Percys}
            img_l_px="480"
            img_s={Percys}
            img_s_px="480"
            imgAlt="Percys Pet Products"
            overlay="flex"
            link="/project/percys-pet-products"
            client="Percys Pet Products"
            desc="Branding, Design, Development..."
          />
          <HomeClientBox
            size="landscape"
            img_l={Specsavers}
            img_l_px="960"
            img_s={Specsavers}
            img_s_px="960"
            imgAlt="DrivenMedia"
            overlay="flex"
            link="/project/drivenmedia"
            client="Driven Media"
            desc="Design..."
          />
          <HomeClientBox
            size="small"
            img_l={Prym}
            img_l_px="480"
            img_s={Prym}
            img_s_px="480"
            imgAlt="Prym Health"
            overlay="flex"
            link="/project/prym-health"
            client="Prym Health"
            desc="Branding, Design, Development, Print, Packaging, Social..."
          />
        </div>
      </div>
    </div>
  );
};

export default HomeClientBoxes;
