import { Link } from "react-router-dom";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";

function RightText() {
  return (
    <div className="container home-split-gif">
      <div className="right-text">
        <h2>
          <span>Quintessential</span> <br />
          British Design.
        </h2>
        <p>
          Our team are super passionate about all things{" "}
          <Link onClick={ScrollTop} to="/service/design">
            design
          </Link>{" "}
          and{" "}
          <Link onClick={ScrollTop} to="/service/web-design">
            web
          </Link>{" "}
          and will go the extra mile to ensure you're a happy customer. We value
          each client as much as the last - without our amazing clients none of
          this is possible. With over 20 years of valuable design & marketing
          experience, you know your brand is moving forward in the right hands.
        </p>
        <div className="serv-links">
          <div>
            <Link onClick={ScrollTop} to="/service/branding">
              Branding
            </Link>
          </div>
          <div>
            <Link onClick={ScrollTop} to="/service/web-design">
              Web Design
            </Link>
          </div>
          <div>
            <Link onClick={ScrollTop} to="/service/design">
              Graphic Design
            </Link>
          </div>
          <div>
            <Link onClick={ScrollTop} to="/services">
              All Our Services
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightText;
