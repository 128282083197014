import SinglePostInner from "../Components/SinglePostInner/SinglePostInner";
import SEO from "../SEO";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import OtherArticles from "../Components/SinglePostInner/OtherArticles";
import Loading from "../Components/Universal/SingleElements/Loading/Loading";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";

const SinglePost = () => {
  const params = useParams();
  const [post, setPost] = useState();

  useEffect(() => {
    const postData = {
      "fields.slug": params.slug,
      content_type: "blogPost",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((entry) => {
        setPost(entry.data.items);
        ScrollTop();
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [params.slug]);

  if (!post) {
    return <Loading />;
  } else {
    return (
      <section id="single-post">
        <SEO
          title={post[0].fields.seoTitle}
          description={post[0].fields.seoDescription}
          keywords={post[0].fields.seoKeywords.map((tag) => tag + " ")}
        />
        <div className="container">
          <SinglePostInner props={post} />
        </div>
        <OtherArticles />
      </section>
    );
  }
};
export default SinglePost;
