import { Outlet } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
// import { motion } from "framer-motion";

function Layout(props) {
  return (
    <div>
      {/* <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      > */}
      <Header
        background={props.background}
        split={props.split}
        absolute={props.absolute}
        color={props.color}
      />
      <Outlet />
      {/* </motion.main> */}
      <Footer />
    </div>
  );
}
export default Layout;
