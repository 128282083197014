const NewsCategoryOption = (props) => {
  return (
    <button
      onClick={(e) => {
        props.selected(e);
      }}
      id={props.id}
      className={props.class}
    >
      {props.name}
    </button>
  );
};
export default NewsCategoryOption;
