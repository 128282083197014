import { Link } from "react-router-dom";
import Open from "./FooterImages/open-accordion.svg";
import Close from "./FooterImages/close-accordion.svg";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";
import PeterGriffin from "./FooterImages/peter-griffin.gif";

const PageCta = () => {
  const toggleAccordion = (item) => {
    if (item.classList.contains("open")) {
      item.querySelector(".accordion-container").style.height = `0px`;
      item.classList.remove("open");
    } else {
      item.querySelector(".accordion-container").style.height = `${
        item.querySelector(".accordion-text").getBoundingClientRect().height
      }px`;
      item.classList.add("open");
    }
  };
  return (
    <div className="footer-cta page">
      <div className="container crush-call">
        <div className="callback-div">
          <h2 className="light">
            <b>Ready to say no </b>
            <br />
            to mediocre? 👀
            <img
              width="262"
              height="252"
              loading="lazy"
              src={PeterGriffin}
              alt="Peter Griffin Dancing"
            />
          </h2>
          <Link to="/contact/" onClick={ScrollTop}>
            Arrange A Callback
          </Link>
        </div>
        <div className="cta-drops">
          <div className="accordion">
            <div className="each-accordion">
              <button
                className="accordion-toggle p-lg"
                onClick={(e) => {
                  toggleAccordion(e.target.closest(".each-accordion"));
                }}
              >
                What are the average lead times?
                <span className="open">
                  <img
                    loading="lazy"
                    width="14px"
                    height="14px"
                    src={Open}
                    alt="Plus"
                  />
                </span>
                <span className="close">
                  <img
                    loading="lazy"
                    width="14px"
                    height="2px"
                    src={Close}
                    alt="Minus"
                  />
                </span>
              </button>
              <div className="accordion-container">
                <div className="accordion-text">
                  <p>
                    Lead times can differ depending on the nature and complexity
                    of the project. For services like{" "}
                    <Link to="/service/branding" onClick={ScrollTop}>
                      branding
                    </Link>
                    ,{" "}
                    <Link to="/service/print-design" onClick={ScrollTop}>
                      print design
                    </Link>
                    , and{" "}
                    <Link to="/service/web-design" onClick={ScrollTop}>
                      web design
                    </Link>
                    , the average lead times may vary. Typically, we aim to
                    provide estimates tailored to each project's specific
                    requirements during the consultation phase.
                  </p>
                  <p>
                    While lead times may fluctuate, we prioritize meeting your
                    deadlines whenever possible. If you have urgent
                    requirements, please communicate them with our team, and we
                    will do our utmost to accommodate your needs without
                    compromising on quality.
                  </p>
                  <p>
                    For more precise information regarding lead times for your
                    project, feel free to{" "}
                    <Link to="/contact" onClick={ScrollTop}>
                      get in touch
                    </Link>{" "}
                    with our team. We're here to assist you every step of the
                    way.
                  </p>
                </div>
              </div>
            </div>
            <div className="each-accordion">
              <button
                className="accordion-toggle p-lg"
                onClick={(e) => {
                  toggleAccordion(e.target.closest(".each-accordion"));
                }}
              >
                What is the design process?
                <span className="open">
                  <img
                    loading="lazy"
                    width="14px"
                    height="14px"
                    src={Open}
                    alt="Plus"
                  />
                </span>
                <span className="close">
                  <img
                    loading="lazy"
                    width="14px"
                    height="2px"
                    src={Close}
                    alt="Minus"
                  />
                </span>
              </button>
              <div className="accordion-container">
                <div className="accordion-text">
                  <p>
                    In our brand naming approach, we commence with a
                    comprehensive "discovery meeting" where we delve into
                    understanding your product or service, values, and
                    preferences. Following this, our creative team independently
                    explores name ideas, collaborates for a group review, and
                    ultimately presents you with a refined list of the most
                    promising names for your consideration.
                  </p>
                  <p>
                    As for the logo design process, our proficient in-house
                    graphic designers, well-versed in their craft, invest time
                    and effort in researching your company. They create multiple
                    custom logo concepts and variations, ensuring each design
                    aligns seamlessly with your brand. Additionally, we
                    meticulously test these concepts to evaluate their
                    effectiveness across various media types, including web,
                    print, digital ads, signage, and clothing.
                  </p>
                </div>
              </div>
            </div>
            <div className="each-accordion">
              <button
                className="accordion-toggle p-lg"
                onClick={(e) => {
                  toggleAccordion(e.target.closest(".each-accordion"));
                }}
              >
                Why should I choose Hyphen?
                <span className="open">
                  <img
                    loading="lazy"
                    width="14px"
                    height="14px"
                    src={Open}
                    alt="Plus"
                  />
                </span>
                <span className="close">
                  <img
                    loading="lazy"
                    width="14px"
                    height="2px"
                    src={Close}
                    alt="Minus"
                  />
                </span>
              </button>
              <div className="accordion-container">
                <div className="accordion-text">
                  <p>
                    We're a passionate group of creatives operating from our
                    head office in the stunning town of Belper in the heart of
                    the UK. We've got plenty of design and advertising
                    experience and we use this to develop our skills daily. We
                    have clients both large and small but we make sure each is
                    treated with as much love as the last.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageCta;
