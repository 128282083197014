function ConvertContentfulDate(date) {
  const parseDate = Date.parse(date);
  const newDate = new Date(parseDate);
  const day = newDate.getDate();
  const month = newDate.toLocaleString("default", { month: "long" });
  const year = newDate.getFullYear();

  return `${day} ${month} ${year}`;
}

export default ConvertContentfulDate;
