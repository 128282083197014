import "./Footer.css";
import Logo from "./FooterImages/HyphenLogoMain.svg";
import Shopify from "./FooterImages/shopify-partner-white.svg";
import Top3 from "./FooterImages/top-3.webp";
import Google from "./FooterImages/google-reviews.svg";
import Trustpilot from "./FooterImages/trustpilot.svg";
import { Link, useLocation } from "react-router-dom";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";
import PageCta from "./PageCta";
import HomeCta from "./HomeCta";

function Footer() {
  const currentYear = new Date().getFullYear();
  const loc = useLocation();
  return (
    <footer>
      {loc.pathname.length > 1 ? <PageCta /> : <HomeCta />}
      <div className="main-footer">
        <div className="container">
          <div className="footer-flex">
            <Link to="/" onClick={ScrollTop}>
              <img
                width="186px"
                height="30px"
                loading="lazy"
                className="footer-logo"
                src={Logo}
                alt="Hyphen Logo"
              />
            </Link>
            <div className="footer-nav">
              <ul>
                <li>
                  <Link to="tel:01773825690">01773 825 690</Link>
                </li>
                <li>
                  <Link to="mailto:hello@hyphencreative.co.uk">
                    hello@hyphencreative.co.uk
                  </Link>
                </li>
                <li>Belper, Derbyshire, UK</li>
                <li>Company number 10716089</li>
              </ul>
              <ul>
                <li>
                  <Link onClick={ScrollTop} to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link onClick={ScrollTop} to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link onClick={ScrollTop} to="/services">
                    Our Services
                  </Link>
                </li>
                <li>
                  <Link onClick={ScrollTop} to="/projects">
                    Our Work
                  </Link>
                </li>
                <li>
                  <Link onClick={ScrollTop} to="/contact">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://stats.uptimerobot.com/3GWBqUX69K"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Server Status
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link
                    to="https://www.facebook.com/hyphencreativeuk/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/hyphen.creative/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://za.pinterest.com/hyphen__creative/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pintrest
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/hyphencreative/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="shopify">
              <img
                loading="lazy"
                width="212.77px"
                height="36.794px"
                src={Shopify}
                alt="Shopify Partner"
              />
              <img
                loading="lazy"
                width="100px"
                height="100px"
                src={Top3}
                alt="Top 3 Designer"
              />
            </div>
            <div className="smallprint-box">
              <div className="smallprint">
                <p>&copy;Copyright {currentYear}</p>
                <Link onClick={ScrollTop} to="/terms">
                  Terms of Use
                </Link>
                <Link onClick={ScrollTop} to="/privacy-policy">
                  Privacy Policy
                </Link>
              </div>
              <div className="trust-google">
                <Link
                  to="https://www.shopify.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width="112px"
                    height="30px"
                    loading="lazy"
                    className="footer-logo"
                    src={Trustpilot}
                    alt="Shopify"
                  />
                </Link>
                <Link
                  to="https://g.page/r/CbTsLjDX86tXEBM/review"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width="204px"
                    height="30px"
                    loading="lazy"
                    className="footer-logo"
                    src={Google}
                    alt="Reviews"
                  />
                </Link>
                <Link to="https://order.cwcs.co.uk/billing/aff.php?aff=246">
                  <img
                    src="http://www.cwcs.co.uk/images/affiliate/175x40.gif"
                    width="175"
                    height="40"
                    border="0"
                    alt="CWCS"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
