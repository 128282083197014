import axios from "axios";
import "../Components/ProjectsPage/ProjectsPage.css";
import SEO from "../SEO";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";
import Loading from "../Components/Universal/SingleElements/Loading/Loading";
import SingleProjectLarge from "../Components/SingleProjectPage/SingleProjectLarge";
import SingleProjectSmall from "../Components/SingleProjectPage/SingleProjectSmall";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";

const SingleProject = (props) => {
  const params = useParams();
  const [project, setProject] = useState();
  const touchEl = useRef(null);

  const isTouchDevice = useCallback(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  useEffect(() => {
    setProject(false);

    const postData = {
      "fields.slug": params.slug,
      content_type: "singleProject",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((entry) => {
        setProject(entry.data.items);
        props.headerColor(entry.data.items[0].fields.BackgroundColour);
        props.splitHeaderColor(entry.data.items[0].fields.smallProject);
        props.absoluteHeader(entry.data.items[0].fields.absoluteHeader);
        props.projectSize(entry.data.items[0].fields.smallProject);
        ScrollTop();
        if (isTouchDevice()) {
          touchEl.current.classList.add("touchscreen");
        } else {
          touchEl.current.classList.add("non-touchscreen");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [params.slug, props, isTouchDevice]);

  if (!project) {
    return (
      <div ref={touchEl}>
        <Loading />
      </div>
    );
  } else {
    return (
      <div ref={touchEl}>
        <SEO
          title={project[0].fields.seoTitle}
          description={project[0].fields.seoDescription}
          keywords={project[0].fields.seoKeywords.map((tag) => tag + " ")}
        />
        {project[0].fields.smallProject ? (
          <SingleProjectSmall project={project[0]} />
        ) : (
          <SingleProjectLarge project={project[0]} />
        )}
      </div>
    );
  }
};
export default SingleProject;
