import axios from "axios";
import "../Components/ServicesPage/ServicesPage.css";
import HomeCarousel from "../Components/HomePage/HomeCarousel";
import SEO from "../SEO";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Loading from "../Components/Universal/SingleElements/Loading/Loading";
import ClientBoxes from "../Components/HomePage/HomeClientBoxes";
import ClientFeedback from "../Components/Universal/ClientFeedback/ClientFeedback";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";
import ShopifyPartner from "../Components/ContactPage/ContactPageImages/Shopify-Partner.svg";
import Top3 from "../Components/ContactPage/ContactPageImages/Top-3-Web-Designer.webp";
import Open from "../Components/Footer/FooterImages/open-accordion.svg";
import Close from "../Components/Footer/FooterImages/close-accordion.svg";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import Car from "../Components/HomePage/HomePageImages/Car.webp";
import Case from "../Components/HomePage/HomePageImages/case.webp";
import Circle from "../Components/Universal/Svgs/Circle";
import ScrollIntoView from "../Components/Universal/SingleFunctions/ScrollIntroView";

const toggleAccordion = (item) => {
  if (item.classList.contains("open")) {
    item.querySelector(".accordion-container").style.height = `0px`;
    item.classList.remove("open");
  } else {
    item.querySelector(".accordion-container").style.height = `${
      item.querySelector(".accordion-text").getBoundingClientRect().height
    }px`;
    item.classList.add("open");
  }
};

const SingleServices = () => {
  const params = useParams();
  const [service, setService] = useState();
  const heightDiv = useRef();
  const imgEl = useRef();

  useEffect(() => {
    const postData = {
      "fields.slug": params.slug,
      content_type: "singleServices",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((entry) => {
        setService(entry.data.items);

        setTimeout(() => {
          imgEl.current.style.marginTop = `-${
            heightDiv.current.offsetHeight -
            heightDiv.current.querySelector(".p-lg").offsetHeight -
            50
          }px`;
        }, 100);
        ScrollTop();
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [params.slug]);

  useEffect(() => {
    ScrollIntoView(document.querySelectorAll(".our-aim .each-accordion"), 0);
  }, [service]);

  if (!service) {
    return <Loading />;
  } else {
    return (
      <div>
        <SEO
          title={service[0].fields.seoTitle}
          description={service[0].fields.seoDescription}
          keywords={service[0].fields.seoKeywords.map((tag) => tag + " ")}
        />
        <section className="serviceshero">
          <div className="container">
            <p className="services-hero-sub-heading">
              {service[0].fields.subHeading}
            </p>
            <div className="services-hero-text">
              <div
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(service[0].fields.servicesHero),
                }}
                className="text"
              />
              <div className="services-hero-points">
                <div className="stats">
                  <div className="stat">
                    <div className="icon">🫡</div>
                    <div>
                      <p className="figure">{service[0].fields.stats[0]}</p>
                      <p>{service[0].fields.statsDescription[0]}</p>
                    </div>
                  </div>
                  <div className="stat">
                    <div className="icon">😊</div>
                    <div>
                      <p className="figure">{service[0].fields.stats[1]}</p>
                      <p>{service[0].fields.statsDescription[1]}</p>
                    </div>
                  </div>
                  <div className="stat">
                    <div className="icon">🙏</div>
                    <div>
                      <p className="figure">{service[0].fields.stats[2]}</p>
                      <p>{service[0].fields.statsDescription[2]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services-hero-images">
          <div className="container-lg">
            <div className="services-hero-images">
              <div className="image one">
                <img
                  src={service[0].fields.servicesTwoImages[0].fields.file.url}
                  alt={
                    service[0].fields.servicesTwoImages[0].fields.file.fileName
                  }
                  width={
                    service[0].fields.servicesTwoImages[0].fields.file.details
                      .image.width + "px"
                  }
                  height={
                    service[0].fields.servicesTwoImages[0].fields.file.details
                      .image.height + "px"
                  }
                />
              </div>
              <div className="image two">
                <img
                  src={service[0].fields.servicesTwoImages[1].fields.file.url}
                  alt={
                    service[0].fields.servicesTwoImages[1].fields.file.fileName
                  }
                  width={
                    service[0].fields.servicesTwoImages[1].fields.file.details
                      .image.width + "px"
                  }
                  height={
                    service[0].fields.servicesTwoImages[1].fields.file.details
                      .image.height + "px"
                  }
                />
              </div>
            </div>
          </div>
        </section>

        <section className="our-aim">
          <div className="container">
            <div className="two-two">
              <div className="two-one aim-hyphen" ref={heightDiv}>
                <div
                  className="p-lg"
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(service[0].fields.leftText),
                  }}
                />
              </div>
              <div className="two-one">
                <div
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(service[0].fields.rightText),
                  }}
                />
                <div className="cta-drops">
                  <div className="accordion">
                    <div className="each-accordion">
                      <button
                        className="accordion-toggle p-lg"
                        onClick={(e) => {
                          toggleAccordion(e.target.closest(".each-accordion"));
                        }}
                      >
                        {service[0].fields.accordionTitle1}
                        <span className="open">
                          <img
                            width="14px"
                            height="14px"
                            src={Open}
                            alt="Plus"
                          />
                        </span>
                        <span className="close">
                          <img
                            width="14px"
                            height="2px"
                            src={Close}
                            alt="Minus"
                          />
                        </span>
                      </button>
                      <div className="accordion-container">
                        <div className="accordion-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: documentToHtmlString(
                                service[0].fields.accordionContent1
                              ),
                            }}
                          />
                          <Link to="/contact">
                            <p className="underline-link">Find Out More</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="each-accordion">
                      <button
                        className="accordion-toggle p-lg"
                        onClick={(e) => {
                          toggleAccordion(e.target.closest(".each-accordion"));
                        }}
                      >
                        {service[0].fields.accordionTitle2}
                        <span className="open">
                          <img
                            width="14px"
                            height="14px"
                            src={Open}
                            alt="Plus"
                          />
                        </span>
                        <span className="close">
                          <img
                            width="14px"
                            height="2px"
                            src={Close}
                            alt="Minus"
                          />
                        </span>
                      </button>
                      <div className="accordion-container">
                        <div className="accordion-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: documentToHtmlString(
                                service[0].fields.accordionContent2
                              ),
                            }}
                          />
                          <Link to="/contact">
                            <p className="underline-link">Find Out More</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="each-accordion">
                      <button
                        className="accordion-toggle p-lg"
                        onClick={(e) => {
                          toggleAccordion(e.target.closest(".each-accordion"));
                        }}
                      >
                        {service[0].fields.accordionTitle3}
                        <span className="open">
                          <img
                            width="14px"
                            height="14px"
                            src={Open}
                            alt="Plus"
                          />
                        </span>
                        <span className="close">
                          <img
                            width="14px"
                            height="2px"
                            src={Close}
                            alt="Minus"
                          />
                        </span>
                      </button>
                      <div className="accordion-container">
                        <div className="accordion-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: documentToHtmlString(
                                service[0].fields.accordionContent3
                              ),
                            }}
                          />
                          <Link to="/contact">
                            <p className="underline-link">Find Out More</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="each-accordion">
                      <button
                        className="accordion-toggle p-lg"
                        onClick={(e) => {
                          toggleAccordion(e.target.closest(".each-accordion"));
                        }}
                      >
                        {service[0].fields.accordionTitle4}
                        <span className="open">
                          <img
                            width="14px"
                            height="14px"
                            src={Open}
                            alt="Plus"
                          />
                        </span>
                        <span className="close">
                          <img
                            width="14px"
                            height="2px"
                            src={Close}
                            alt="Minus"
                          />
                        </span>
                      </button>
                      <div className="accordion-container">
                        <div className="accordion-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: documentToHtmlString(
                                service[0].fields.accordionContent4
                              ),
                            }}
                          />
                          <Link to="/contact">
                            <p className="underline-link">Find Out More</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="each-accordion">
                      <button
                        className="accordion-toggle p-lg"
                        onClick={(e) => {
                          toggleAccordion(e.target.closest(".each-accordion"));
                        }}
                      >
                        {service[0].fields.accordionTitle5}
                        <span className="open">
                          <img
                            width="14px"
                            height="14px"
                            src={Open}
                            alt="Plus"
                          />
                        </span>
                        <span className="close">
                          <img
                            width="14px"
                            height="2px"
                            src={Close}
                            alt="Minus"
                          />
                        </span>
                      </button>
                      <div className="accordion-container">
                        <div className="accordion-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: documentToHtmlString(
                                service[0].fields.accordionContent5
                              ),
                            }}
                          />
                          <Link to="/contact/">
                            <p className="underline-link">Find Out More</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Link to="/projects" onClick={ScrollTop}>
                  <button className="blue-btn">View our work</button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <img
          className="our-aim-img"
          ref={imgEl}
          src={service[0].fields.accordionImage.fields.file.url}
          alt={service[0].fields.accordionImage.fields.file.fileName}
          width={
            service[0].fields.accordionImage.fields.file.details.image.width +
            "px"
          }
          height={
            service[0].fields.accordionImage.fields.file.details.image.height +
            "px"
          }
        />

        <section className="made-mission">
          <div className="container">
            <div className="two-two align-center">
              <div className="mission-text">
                <div className="awards">
                  <img
                    width="212.77px"
                    height="36.794px"
                    src={ShopifyPartner}
                    alt="Shopify Partner"
                  />
                  <img
                    width="71px"
                    height="71px"
                    src={Top3}
                    alt="Top 3 Website Designer 2021"
                  />
                </div>
                <h3>{service[0].fields.mainStatement}</h3>
              </div>
            </div>
          </div>
        </section>

        <div className="split-text-con">
          <div className="container">
            <div className="split-text third">
              <p className="p-lg">
                We're a
                <span className="jeff">
                  {" "}
                  passionate {<Circle stroke="5" />}
                </span>
                bunch of creatives and developers who strive to achieve for our
                clients,{" "}
                <img
                  loading="lazy"
                  width="30px"
                  height="27px"
                  src={Case}
                  alt="Case"
                />{" "}
                big or{" "}
                <img
                  className="car"
                  loading="lazy"
                  width="55px"
                  height="30px"
                  src={Car}
                  alt="Car"
                />{" "}
                small.
              </p>
              <p className="text-right">
                We're a British-based creative design studio that excels in the
                <Link to="/service/design"> design</Link>, and deployment of
                innovative cross-platform brand experiences. Our team delivers
                web design, brand strategy and digital marketing services across
                the world for clients such as large corporate clients and small
                independent traders.
              </p>
            </div>
          </div>
        </div>

        <HomeCarousel images={service[0].fields.carousel} />

        <div className="universal-quote">
          <div className="container">
            <p className="quote-content">
              Hyphen Creative was born out of a passion for design and web
              development. Our creative services have grown year on year as our
              team continues to develop. Our passion for all things design gives
              us the motivation to strive for the best for all of our clients,
              big or small.
            </p>
            <p className="jeff">Stephen Kay</p>
            <p>Co-founder</p>
          </div>
        </div>

        <div className="container">
          <h2 className="lprojects">
            <span>Latest projects</span>
            <br />
            from our design team.
          </h2>
        </div>
        <ClientBoxes />
        <ClientFeedback />
      </div>
    );
  }
};
export default SingleServices;
