const Circle = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      data-name="Layer 2"
      viewBox="-5 -5 265.29 130.4"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="110.45"
          y1="2.94"
          x2="144.51"
          y2="121.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#60c0bf" />
          <stop offset="1" stopColor="#2da5dd" />
        </linearGradient>
      </defs>
      <g id="PRINT">
        <g id="Group_25805" data-name="Group 25805">
          <g id="Group_741" data-name="Group 741">
            <path
              stroke="url(#linear-gradient)"
              strokeWidth={props.stroke}
              strokeLinecap="round"
              fill="transparent"
              id="Path_36257"
              data-name="Path 36257"
              d="M117.36,27.8c10.67-4.38,22.33-6.75,33.65-8.47,11.71-1.78,23.34-2.13,34.94-2.31,11.3-.17,22.26.39,32.6,3.09,10.11,2.64,19.07,7.14,25.46,13.72,6.23,6.43,9.82,14.84,9.22,23.61-.59,8.47-5.36,16.7-12.64,23.59-6.92,6.55-15.83,11.77-24.99,15.97-10.11,4.53-20.56,8.28-31.24,11.22-22.07,6.25-44.85,9.65-67.79,10.12-21.96.52-43.88-2.04-65.13-7.59-20.4-5.46-41.98-14.12-48.05-31.8-2.89-8.43-1.18-17.51,4.78-25.9,5.62-7.92,14.08-14.68,23.26-20.1,10.64-6.28,22.82-10.96,34.73-14.95,12.85-4.23,25.95-7.68,39.22-10.31,12.18-2.6,24.52-4.36,36.94-5.26,11.89-.78,23.84-.45,35.67.99,5.43.69,10.82,1.66,16.15,2.93"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Circle;
