import ConvertContentfulDate from "../Universal/SingleFunctions/ConvertContentfulDate";
import { Link } from "react-router-dom";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";

const NewsPageItem = (props) => {
  const date = ConvertContentfulDate(props.data.blogPostDate);
  return (
    <div className="news-item">
      {/* {console.log(props)} */}
      <Link
        onClick={ScrollTop}
        to={"/post/" + props.data.slug}
        state={{ data: props.data }}
      >
        <div className="image">
          <img
            width="100%"
            src={props.data.blogFeaturedImage.fields.file.url}
            alt={props.data.blogFeaturedImage.fields.title}
          />
          <span className="image-cat">{props.data.blogCategory}</span>
        </div>
        <div className="details">
          <h2>{props.data.blogTitle}</h2>
          <p>{date}</p>
        </div>
      </Link>
    </div>
  );
};

export default NewsPageItem;
