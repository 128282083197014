import HomerGif from "../Components/404Page/404PageImages/HomerGif.gif";
import "../Components/404Page/404Page.css";
import { Link } from "react-router-dom";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";
import SEO from "../SEO";
import { useEffect } from "react";

const Err404 = (props) => {
  useEffect(() => {
    props.updateLogoColor("white");
    props.updateAbsoluteHeader(true);
  });

  return (
    <section id="err-404">
      <SEO
        title="Hyphen Creative | Page Not Found"
        description="Hyphen Creative are British-based design agency that excels in the design, and deployment of innovative cross-platform brand experiences."
        keywords="404, No Page"
      />
      <img className="homer gif" src={HomerGif} alt="Homer Missing" />
      <div className="container">
        <div className="err-404">
          <div className="text">
            <p>404, page not found.</p>
            <h1>Doh! This is awkward...</h1>
            <Link to="/" onClick={ScrollTop}>
              <button className="white-trans-btn" type="submit">
                Go Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Err404;
