import { Link } from "react-router-dom";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";
import PeterGriffin from "./FooterImages/peter-griffin.gif";

const HomeCta = () => {
  return (
    <div className="footer-cta home">
      <div className="container crush-call">
        <h2 className="light">
          Let's crush it <br />
          <b>together</b>
          <img
            width="262"
            height="252"
            loading="lazy"
            src={PeterGriffin}
            alt="Peter Griffin Dancing"
          />
        </h2>
        <div className="cta-links">
          <Link to="/contact/" onClick={ScrollTop}>
            Arrange A Callback
          </Link>
          <Link to="mailto:hello@hyphencreative.co.uk">Email Us</Link>
        </div>
      </div>
    </div>
  );
};

export default HomeCta;
