import HomeHero from "../Components/HomePage/HomeHero";
import ProudlyTrustedBy from "../Components/Universal/ProudlyTrustedBy/ProudlyTrustedBy";
import HomeClientBoxes from "../Components/HomePage/HomeClientBoxes";
import HomeSplitText from "../Components/HomePage/HomeSplitText";
import HomeCarousel from "../Components/HomePage/HomeCarousel";
import RightText from "../Components/HomePage/RightText";
import CollectionPosts from "../Components/Universal/CollectionPosts/CollectionPosts";
import ClientFeedback from "../Components/Universal/ClientFeedback/ClientFeedback";
import HomeSideScroll from "../Components/HomePage/HomeSideScroll";
import SEO from "../SEO";
import "../Components/HomePage/HomePage.css";
import { useEffect, useState } from "react";
import axios from "axios";

function HomePage() {
  const [carousel, setCarousel] = useState();

  useEffect(() => {
    const postData = {
      content_type: "homePage",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((entry) => {
        setCarousel(entry.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <SEO
        title="Design Agency Derby | Hyphen Creative | Web Design • Branding • UK"
        description="Hyphen Creative are British-based design agency that excels in the design, and deployment of innovative cross-platform brand experiences."
        keywords="Web, Design, Development"
      />
      <HomeHero />
      <ProudlyTrustedBy />
      <HomeClientBoxes />
      <HomeSplitText />
      {carousel ? <HomeCarousel images={carousel[0].fields.carousel} /> : ""}
      <RightText />
      <HomeSideScroll />
      <CollectionPosts />
      <ClientFeedback />
      <section id="home-final-text">
        <div className="container">
          <div className="home-final-text">
            <p className="p-lg">
              There are three responses to a piece of design - yes, no, and wow!
              <br />
              <span className="bold">Wow is the one to aim for.</span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
