import { useEffect, useState } from "react";
import NewsPageItem from "./NewsPageItem";
import axios from "axios";
import ScrollIntoView from "../Universal/SingleFunctions/ScrollIntroView";

const NewsPageItems = (props) => {
  const [selectedPosts, setSelectedPosts] = useState(false);

  useEffect(() => {
    const postData = {
      content_type: "blogPost",
      "fields.blogCategory": props.cat,
      order: "-fields.blogPostDate",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((res) => {
        setSelectedPosts(res.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.cat]);

  useEffect(() => {
    ScrollIntoView(document.querySelectorAll(".news-item"), 1);
  }, [selectedPosts]);

  if (selectedPosts) {
    return (
      <div className="news-items">
        {selectedPosts.map((post) => (
          <NewsPageItem key={post.sys.id} data={post.fields} />
        ))}
      </div>
    );
  }
};

export default NewsPageItems;
