import "./ProudlyTrustedBy.css";
import Images0 from "./Images/dennis-logo.svg";
// import Images1 from "./Images/ancient-grain-logo.svg";
// import Images2 from "./Images/neighbourhood-watch-logo.svg";
import Images3 from "./Images/adidas-logo.svg";
import Images4 from "./Images/best-of-london-logo.svg";
import Images5 from "./Images/nhs-logo.svg";
import Images6 from "./Images/university-of-derby-logo.svg";
import Images7 from "./Images/driven-media-logo.svg";
import Images8 from "./Images/bowmer-kirkland-logo.svg";
import Images9 from "./Images/prym-health-logo.svg";
import Images10 from "./Images/hsl-logo.svg";
import Images11 from "./Images/porter-brook-logo.svg";
import Images12 from "./Images/drager-logo.svg";
import Images13 from "./Images/sc-johnson-logo.svg";
import Images14 from "./Images/star-logo.svg";
import Images15 from "./Images/talk-to-a-peer-logo.svg";
import Images16 from "./Images/tough-enough-to-care-logo.svg";
import Images17 from "./Images/vet-care-home-logo.svg";
import Images18 from "./Images/percys-pet-products-logo.svg";
import Images19 from "./Images/project-wingman-logo.svg";
import Images20 from "./Images/morvern-group-logo.svg";
import Images21 from "./Images/matlock-rugby-club-logo.svg";
import Images22 from "./Images/be-defiant-logo.svg";
import OD from "./Images/OdiliaClark.svg";
import { Link } from "react-router-dom";
import ScrollTop from "../SingleFunctions/ScrollTop";

const ProudlyTrustedBy = () => {
  return (
    <div className="container" id="proudly-trusted-by">
      <div className="proudly-trusted-by">
        <p>Proudly Trusted By</p>
        <div className="image-overflow">
          <div className="brands">
            <Link onClick={ScrollTop} to="/project/dennis-group">
              <img
                width="111.99px"
                height="25.618px"
                src={Images0}
                alt="Dennis"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/adidas">
              <img
                width="64.73px"
                height="43.432px"
                src={Images3}
                alt="Adidas"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/best-of-south-west-ldn">
              <img
                width="64.34px"
                height="63.034px"
                src={Images4}
                alt="Best of South West London"
              />
            </Link>
            <Link to="https://www.nhs.uk" target="_blank">
              <img width="82.225px" height="32.89px" src={Images5} alt="NHS" />
            </Link>
            <Link to="https://www.derby.ac.uk" target="_blank">
              <img
                width="111.148px"
                height="23.48px"
                src={Images6}
                alt="University of Derby"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/drivenmedia">
              <img
                width="116.521px"
                height="17.634px"
                src={Images7}
                alt="Driven Media"
              />
            </Link>
            <Link to="https://www.bandk.co.uk" target="_blank">
              <img
                width="110.546px"
                height="34.77px"
                src={Images8}
                alt="Bowmer & Kirkland"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/odiliaclark">
              <img width="144.003" height="22.79" src={OD} alt="OdiliaClark" />
            </Link>
            <Link onClick={ScrollTop} to="/project/prym-health">
              <img
                width="92.544px"
                height="35.938px"
                src={Images9}
                alt="Prym Health"
              />
            </Link>
            <Link to="https://hsl.ie" target="_blank">
              <img
                width="106.37px"
                height="42.207px"
                src={Images10}
                alt="Hospital Services Limited"
              />
            </Link>
            <Link onClick={ScrollTop} to="/projects/">
              <img
                width="52.501px"
                height="55.353px"
                src={Images11}
                alt="Porter Brook"
              />
            </Link>
            <Link to="https://www.draeger.com/en_uk/Home" target="_blank">
              <img
                width="99.739px"
                height="38.649px"
                src={Images12}
                alt="Drager"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/sc-johnson">
              <img
                width="109.257px"
                height="36px"
                src={Images13}
                alt="SC Johnson"
              />
            </Link>
            <Link to="https://stargb.com" target="_blank">
              <img
                width="91.762px"
                height="32.461px"
                src={Images14}
                alt="Star"
              />
            </Link>
            <Link to="https://talktoapeer.com" target="_blank">
              <img
                width="116.774px"
                height="48.747px"
                src={Images15}
                alt="Talk To A Peer"
              />
            </Link>
            <Link to="https://toughenoughtocare.help" target="_blank">
              <img
                width="152.119px"
                height="26.84px"
                src={Images16}
                alt="Tough Enough To Care"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/vetcare-at-home">
              <img
                width="105.672px"
                height="49.404px"
                src={Images17}
                alt="VetCare@Home"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/percys-pet-products">
              <img
                width="80.617px"
                height="46.483px"
                src={Images18}
                alt="Percys Pet Products"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/project-wingman">
              <img
                width="52.766px"
                height="56.457px"
                src={Images19}
                alt="Project Wingman"
              />
            </Link>
            <Link to="http://morverngroup.com" target="_blank">
              <img
                width="118.631px"
                height="32.566px"
                src={Images20}
                alt="Morvern Group"
              />
            </Link>
            <Link to="https://www.pitchero.com/clubs/matlock" target="_blank">
              <img
                width="36.117px"
                height="45.925px"
                src={Images21}
                alt="Matlock Rugby Club"
              />
            </Link>
            <Link to="https://bedefiantclothing.co.uk" target="_blank">
              <img
                width="166.893px"
                height="16.801px"
                src={Images22}
                alt="Be Defiant"
              />
            </Link>
          </div>
          <div className="brands overflow">
            <Link onClick={ScrollTop} to="/project/dennis-group">
              <img
                width="111.99px"
                height="25.618px"
                src={Images0}
                alt="Dennis"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/adidas">
              <img
                width="64.73px"
                height="43.432px"
                src={Images3}
                alt="Adidas"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/best-of-south-west-ldn">
              <img
                width="64.34px"
                height="63.034px"
                src={Images4}
                alt="Best of South West London"
              />
            </Link>
            <Link to="https://www.nhs.uk" target="_blank">
              <img width="82.225px" height="32.89px" src={Images5} alt="NHS" />
            </Link>
            <Link to="https://www.derby.ac.uk" target="_blank">
              <img
                width="111.148px"
                height="23.48px"
                src={Images6}
                alt="University of Derby"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/drivenmedia">
              <img
                width="116.521px"
                height="17.634px"
                src={Images7}
                alt="Driven Media"
              />
            </Link>
            <Link to="https://www.bandk.co.uk" target="_blank">
              <img
                width="110.546px"
                height="34.77px"
                src={Images8}
                alt="Bowmer & Kirkland"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/odiliaclark">
              <img width="144.003" height="22.79" src={OD} alt="OdiliaClark" />
            </Link>
            <Link onClick={ScrollTop} to="/project/prym-health">
              <img
                width="92.544px"
                height="35.938px"
                src={Images9}
                alt="Prym Health"
              />
            </Link>
            <Link to="https://hsl.ie" target="_blank">
              <img
                width="106.37px"
                height="42.207px"
                src={Images10}
                alt="Hospital Services Limited"
              />
            </Link>
            <Link onClick={ScrollTop} to="/projects/">
              <img
                width="52.501px"
                height="55.353px"
                src={Images11}
                alt="Porter Brook"
              />
            </Link>
            <Link to="https://www.draeger.com/en_uk/Home" target="_blank">
              <img
                width="99.739px"
                height="38.649px"
                src={Images12}
                alt="Drager"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/sc-johnson">
              <img
                width="109.257px"
                height="36px"
                src={Images13}
                alt="SC Johnson"
              />
            </Link>
            <Link to="https://stargb.com" target="_blank">
              <img
                width="91.762px"
                height="32.461px"
                src={Images14}
                alt="Star"
              />
            </Link>
            <Link to="https://talktoapeer.com" target="_blank">
              <img
                width="116.774px"
                height="48.747px"
                src={Images15}
                alt="Talk To A Peer"
              />
            </Link>
            <Link to="https://toughenoughtocare.help" target="_blank">
              <img
                width="152.119px"
                height="26.84px"
                src={Images16}
                alt="Tough Enough To Care"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/vetcare-at-home">
              <img
                width="105.672px"
                height="49.404px"
                src={Images17}
                alt="VetCare@Home"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/percys-pet-products">
              <img
                width="80.617px"
                height="46.483px"
                src={Images18}
                alt="Percys Pet Products"
              />
            </Link>
            <Link onClick={ScrollTop} to="/project/project-wingman">
              <img
                width="52.766px"
                height="56.457px"
                src={Images19}
                alt="Project Wingman"
              />
            </Link>
            <Link to="https://www.morverngroup.com" target="_blank">
              <img
                width="118.631px"
                height="32.566px"
                src={Images20}
                alt="Morvern Group"
              />
            </Link>
            <Link to="https://www.pitchero.com/clubs/matlock/" target="_blank">
              <img
                width="36.117px"
                height="45.925px"
                src={Images21}
                alt="Matlock Rugby Club"
              />
            </Link>
            <Link to="https://bedefiantclothing.co.uk" target="_blank">
              <img
                width="166.893px"
                height="16.801px"
                src={Images22}
                alt="Be Defiant"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProudlyTrustedBy;
