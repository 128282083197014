import "./HomeSideScroll.css";
import TwoMil from "./HomePageImages/Developer.svg";
import SideProjects from "./HomePageImages/Designer.svg";
import SideLoop from "./HomePageImages/side-loop.svg";
import SideClients from "./HomePageImages/Designer-Alt.svg";
import SideCoffee from "./HomePageImages/countless-coffee.svg";
import SideExcept from "./HomePageImages/side-except.svg";
import AZ from "./HomePageImages/A-Z.svg";
import { useRef, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";
import Circle from "../Universal/Svgs/Circle";

const HomeSideScroll = () => {
  // useRef is used for a mutable value which holds between each render.
  const scrollSection = useRef(null);
  const [scrollState, setScrollState] = useState("top");
  let scrollDistance = useRef(0);
  let scrollWidth = useRef(0);

  // useCallback is called on functions in useEffect so that they are not duplicated each render.
  // Active when above the side scroller
  const watchTopHandler = useCallback((e) => {
    if (scrollSection.current) {
      if (
        scrollSection.current.getBoundingClientRect().top <= 0 &&
        e.deltaY > 0 &&
        scrollDistance.current === 0
      ) {
        setScrollState("active");
        window.removeEventListener("wheel", watchTopHandler);
      }
    } else {
      return;
    }
  }, []);

  const isTouchDevice = useCallback(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  // Active when below the side scroller
  const watchBottomHandler = useCallback((e) => {
    if (scrollSection.current) {
      if (
        scrollSection.current.getBoundingClientRect().top >= 0 &&
        e.deltaY < 0 &&
        scrollDistance.current === scrollWidth.current
      ) {
        setScrollState("active");
        window.removeEventListener("wheel", watchBottomHandler);
      }
    } else {
      return;
    }
  }, []);

  // Active when side scrolling
  const watchActiveHandler = useCallback(
    (e) => {
      if (scrollSection.current) {
        window.scrollTo({
          top:
            scrollSection.current.getBoundingClientRect().top + window.scrollY,
          left: 0,
          behavior: "instant",
        });
        if (
          e.deltaY < 0 &&
          scrollState === "active" &&
          scrollDistance.current <= 0
        ) {
          setScrollState("top");
          scrollDistance.current = 0;
          window.removeEventListener("wheel", watchActiveHandler);
        } else if (
          e.deltaY > 0 &&
          scrollState === "active" &&
          scrollDistance.current >= scrollWidth.current
        ) {
          setScrollState("bottom");
          scrollDistance.current = scrollWidth.current;
          window.removeEventListener("wheel", watchActiveHandler);
        } else if (e.deltaY > 0 && scrollState === "active") {
          // Set scroll distance
          scrollSection.current.scrollLeft = Math.round(
            (scrollDistance.current += e.deltaY / 2)
          );
        } else if (e.deltaY < 0 && scrollState === "active") {
          // Set scroll distance
          scrollSection.current.scrollLeft = Math.round(
            (scrollDistance.current += e.deltaY / 2)
          );
        }
      } else {
        return;
      }
    },
    [scrollDistance, scrollWidth, scrollState, scrollSection]
  );

  useEffect(() => {
    if (!isTouchDevice()) {
      // Add event listeners to top check
      if (scrollState === "top") {
        document.querySelector("body").style.overflow = "visible";
        window.addEventListener("wheel", watchTopHandler);
      }
      // Add event listeners to bottom check
      else if (scrollState === "bottom") {
        document.querySelector("body").style.overflow = "visible";
        // window.addEventListener("wheel", watchBottomHandler);
      }
      // Add event listeners to active check
      else if (scrollState === "active") {
        document.querySelector("body").style.overflow = "hidden";
        window.addEventListener("wheel", watchActiveHandler);
      }
      // Set scroll width
      scrollWidth.current =
        scrollSection.current
          .querySelector(".home-side-scroll-overflow")
          .getBoundingClientRect().width - window.innerWidth;
      // Log details
      // console.log(params);
    } else {
      scrollSection.current.classList.add("touch");
    }
  }, [
    scrollState,
    scrollDistance,
    scrollSection,
    watchActiveHandler,
    watchBottomHandler,
    watchTopHandler,
    isTouchDevice,
  ]);

  const removeOverflow = () => {
    document.querySelector("body").style.overflow = "visible";
    return;
  };

  return (
    <section ref={scrollSection} id="home-side-scroll">
      <div className="home-side-scroll-overflow">
        <div className="first">
          <div>
            <h2>
              <span>Say no</span> to mediocre, <br />
              it'll do, ok, alright.
            </h2>
            <div className="text">
              <p>
                The team here at Hyphen Creative are time-served working in
                agency environments. On the surface that makes us seem like
                we're like other agencies...
              </p>
              <img
                width="359px"
                height="57px"
                loading="lazy"
                src={SideExcept}
                alt="Except, we aren't"
              />
            </div>
          </div>
        </div>
        <div className="one">
          <h2>
            We're <br /> <span className="bold">passionate.</span>
          </h2>
          <p>
            We're a passionate bunch, we strive for absolute perfection and
            we're learning every day to better our service for our clients.
          </p>
        </div>
        <div className="two">
          <img
            width="702px"
            height="840px"
            loading="lazy"
            src={TwoMil}
            alt="2 million lines of code"
          />
        </div>
        <div className="three">
          <p>
            We develop brand concepts and creative communications that help our
            clients stand out,{" "}
            <img
              src={AZ}
              alt="Connect"
              height="22px"
              width="26px"
              style={{ verticalAlign: "middle" }}
            />{" "}
            connect with customers, and stay relevant.
          </p>
        </div>
        <div className="four">
          <div>
            <p className="p-lg">
              <span className="jeff">We're</span> <br />
              <strong>invested.</strong>
            </p>
            <p>
              It's the creative hook that draws people into a brand. Having a
              brand that will make an immediate impact on your customers is
              something we will help you achieve.
            </p>
          </div>
        </div>
        <div className="five">
          <div>
            <img
              loading="lazy"
              width="563.87px"
              height="534.336px"
              src={SideLoop}
              alt="Loop"
            />
            <img
              loading="lazy"
              className="projects"
              width="660"
              height="357"
              src={SideProjects}
              alt="Projects"
            />
          </div>
        </div>
        <div className="six">
          <div>
            <p className="p-lg">
              <span className="jeff">We're</span> <br />
              <strong>innovative.</strong>
            </p>
            <p>
              The technology that's used to tell your story. Our
              <Link to="/service/web-development"> website development </Link>
              team are time-served and experiences in Javascript, HTML, PHP,
              React JS and SaSS.
            </p>
          </div>
        </div>
        <div className="seven">
          <div>
            <img
              loading="lazy"
              width="729.694px"
              height="641.611px"
              src={SideClients}
              alt="200 Clients"
            />
          </div>
        </div>
        <div className="eight">
          <div>
            <p className="p-lg">
              <span className="jeff">We're</span> <br />
              <strong>intrigued.</strong>
            </p>
            <p>
              And the strategy that is informed by an understanding of the
              business. We get to know your business and work alongside you each
              step of the way to ensure we reach our mutual goals.
            </p>
          </div>
          <img
            loading="lazy"
            width="196.947px"
            height="189.5px"
            src={SideCoffee}
            alt="Coffee"
          />
        </div>
        <div className="nine">
          <div>
            <span className="jeff">We care {<Circle stroke="5" />}</span>
            <h2>
              Want to know
              <br />
              <span>more about us?</span>
            </h2>
            <Link
              to="/services"
              onClick={() => {
                ScrollTop();
                removeOverflow();
              }}
            >
              <button className="blue-btn">Our Services</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSideScroll;
