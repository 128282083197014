import "./SinglePostInner.css";
import ConvertContentfulDate from "../Universal/SingleFunctions/ConvertContentfulDate";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useRef, useEffect, useState } from "react";
import ScrollIntoView from "../Universal/SingleFunctions/ScrollIntroView";

const SinglePostInner = (props) => {
  const postData = props.props[0].fields;
  const date = ConvertContentfulDate(postData.blogPostDate);
  const titleCount = useRef(null);
  const titleLinks = useRef(null);
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    const linkList = [];
    const titles = titleCount.current.querySelectorAll("h2");
    titles.forEach((t, i) => {
      t.id = `link-${i}`;
      const a = document.createElement("a");
      a.textContent = t.textContent;
      a.href = `#link-${i}`;
      linkList.push(a);
    });
    titleLinks.current.querySelector("div").innerHTML = "";
    linkList.forEach((b) => {
      titleLinks.current.querySelector("div").append(b);
    });
    window.addEventListener("scroll", function () {
      const h2s = Array.from(document.querySelectorAll(".single-post-main h2"));
      h2s.unshift(document.querySelector(".single-post-intro"));
      const links = Array.from(
        document.querySelectorAll(".single-post-links a")
      );
      links.forEach((a, i) => {
        if (h2s[i].getBoundingClientRect().top <= 1) {
          a.classList.add("viewed");
        } else {
          a.classList.remove("viewed");
        }
      });
    });
    ScrollIntoView(document.querySelectorAll(".single-post-main > *"), 0);
    let count = 0;
    postData.blogText.content.forEach((node) => {
      if (node.nodeType === "paragraph") {
        node.content.forEach((p) => {
          if (p.value) {
            count += p.value.split(" ").length;
          }
        });
      }
    });
    setWordCount(count);
  }, [postData.blogText.content]);

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) =>
        `<img src="${fields.file.url}" height="auto" width="100%" alt="${fields.description}"/>`,
    },
  };
  return (
    <div className="single-post-inner">
      <h1>{postData.blogTitle}</h1>
      <p className="cat">{postData.blogCategory[0]}</p>
      <div className="date-read">
        <p className="date">{date}</p>
        <p className="read-time">{Math.round(wordCount / 100)} minute read</p>
      </div>
      <div className="single-post-hero">
        <img
          src={postData.blogHero[0].fields.file.url}
          alt={postData.blogHero[0].fields.description}
        />
      </div>
      <div className="single-post">
        <div className="single-post-links">
          <div ref={titleLinks}>
            <a href="#link-intro" style={{ order: "0" }}>
              Intro
            </a>
            <div />
          </div>
        </div>
        <div className="single-post-content">
          <div
            className="single-post-intro"
            id="link-intro"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(postData.blogIntro),
            }}
          />
          {/* <ResponsiveImage
            lg={postData.blogInternalImage[0].fields.file.url}
            lg_w={postData.blogInternalImage[0].fields.description}
            sm={postData.blogInternalImage[1].fields.file.url}
            sm_w={postData.blogInternalImage[1].fields.description}
            alt={postData.blogInternalImage[1].fields.title}
          /> */}
          <img
            src={postData.blogInternalImage[0].fields.file.url}
            alt={postData.blogInternalImage[0].fields.description}
          />
          <div
            className="single-post-main"
            ref={titleCount}
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(postData.blogText, options),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default SinglePostInner;
