import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";
import axios from "axios";
import Loading from "../Universal/SingleElements/Loading/Loading";
import ScrollIntoView from "../Universal/SingleFunctions/ScrollIntroView";

const NewsPageItems = (props) => {
  const [selectedProjects, setSelectedProjects] = useState(false);

  useEffect(() => {
    const postData = {
      content_type: "singleProject",
      "fields.category": props.cat,
      order: "fields.projectTitle",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((res) => {
        setSelectedProjects(res.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.cat]);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      ScrollIntoView(document.querySelectorAll(".single-project"), 1);
    } else {
      ScrollIntoView(document.querySelectorAll(".single-project"), 2);
    }
  }, [selectedProjects]);

  if (selectedProjects) {
    return (
      <div className="project-items">
        {selectedProjects.map((project, index, array) => (
          <Link
            key={project.sys.id}
            onClick={() => {
              ScrollTop();
            }}
            to={"/project/" + project.fields.slug}
            state={{
              array: array,
              index: index,
            }}
          >
            <div className="single-project">
              <div className="image-service">
                <img
                  className="project-img"
                  src={project.fields.featuredImage.fields.file.url}
                  alt={project.fields.featuredImage.fields.title}
                />
                <p>{project.fields.category[0]}</p>
              </div>

              <h2>{project.fields.projectTitle}</h2>
              <p>{project.fields.services}</p>
            </div>
          </Link>
        ))}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default NewsPageItems;
