import OpenLink from "./HomePageImages/open-link.svg";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import ScrollTop from "../Universal/SingleFunctions/ScrollTop";

const HomeClientBox = (props) => {
  const [overlayState, setOverlayState] = useState("");
  const link = useRef(null);

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  if (props.link) {
    return (
      <div
        className={"client-box " + props.size}
        onMouseOver={() => {
          setOverlayState("open");
          if (!isTouchDevice()) {
            link.current.style.pointerEvents = "all";
          }
        }}
        onClick={() => {
          if (isTouchDevice()) {
            setTimeout(() => {
              link.current.style.pointerEvents = "all";
            }, 300);
          }
        }}
        onMouseLeave={() => {
          setOverlayState("");
          link.current.style.pointerEvents = "none";
        }}
      >
        <div className={"content " + overlayState}>
          <img
            src={props.img_l}
            width={props.img_l_px}
            height="auto"
            alt={props.imgAlt}
          />
          <Link
            to={props.link}
            className="overlay"
            style={{ display: props.overlay }}
            onClick={ScrollTop}
            ref={link}
          >
            <img className="open-icon" src={OpenLink} alt="Open Link Arrow" />
            <p className="client filson-b">{props.client}</p>
            <p className="description">{props.desc}</p>
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"client-box " + props.size}>
        <div className="content">
          <img
            src={props.img_l}
            width={props.img_l_px}
            height="auto"
            alt={props.imgAlt}
          />
        </div>
      </div>
    );
  }
};

export default HomeClientBox;
